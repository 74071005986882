import React from 'react';
import _ from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const CheckboxCell = ({ row }: GridRenderCellParams) => {
  const selectedCompanies = useShallowSelector((state) => state.customScreen.selectedCompanies);
  const groupedSelectedCompanies = _.keyBy(selectedCompanies, 'id');

  return (
    <Checkbox
      checked={!!groupedSelectedCompanies[row.bain_id]}
      disableRipple
      disableFocusRipple
    />
  );
};
