import React from 'react';

export const IconLoader = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
  >
    <path
      fill="#666"
      d="M9.167 2.5a.833.833 0 1 1 1.666 0v1.667a.833.833 0 1 1-1.666 0V2.5Zm6.666 6.667H17.5a.833.833 0 1 1 0 1.666h-1.667a.833.833 0 1 1 0-1.666ZM5 10a.833.833 0 0 0-.833-.833H2.5a.833.833 0 1 0 0 1.666h1.667c.46 0 .833-.372.833-.833Zm-.994-5.789a.834.834 0 0 1 1.178-.02l1.2 1.158a.833.833 0 0 1-1.16 1.199L4.027 5.389a.833.833 0 0 1-.02-1.178Zm10.19 2.57a.834.834 0 0 0 .58-.233l1.198-1.158a.833.833 0 0 0-1.158-1.2l-1.2 1.158a.833.833 0 0 0 .58 1.433Zm-5.03 9.052a.833.833 0 1 1 1.667 0V17.5a.833.833 0 1 1-1.666 0v-1.667Zm5.609-2.38a.835.835 0 0 0-1.158 1.198l1.199 1.159a.83.83 0 0 0 1.178-.021c.32-.331.311-.86-.02-1.178l-1.199-1.159ZM4.025 14.61l1.2-1.159a.835.835 0 0 1 1.158 1.2L5.184 15.81a.83.83 0 0 1-1.178-.021.833.833 0 0 1 .02-1.178Z"
    />
    <mask
      id="a"
      width="18"
      height="18"
      x="1"
      y="1"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        d="M9.167 2.5a.833.833 0 1 1 1.666 0v1.667a.833.833 0 1 1-1.666 0V2.5Zm6.666 6.667H17.5a.833.833 0 1 1 0 1.666h-1.667a.833.833 0 1 1 0-1.666ZM5 10a.833.833 0 0 0-.833-.833H2.5a.833.833 0 1 0 0 1.666h1.667c.46 0 .833-.372.833-.833Zm-.994-5.789a.834.834 0 0 1 1.178-.02l1.2 1.158a.833.833 0 0 1-1.16 1.199L4.027 5.389a.833.833 0 0 1-.02-1.178Zm10.19 2.57a.834.834 0 0 0 .58-.233l1.198-1.158a.833.833 0 0 0-1.158-1.2l-1.2 1.158a.833.833 0 0 0 .58 1.433Zm-5.03 9.052a.833.833 0 1 1 1.667 0V17.5a.833.833 0 1 1-1.666 0v-1.667Zm5.609-2.38a.835.835 0 0 0-1.158 1.198l1.199 1.159a.83.83 0 0 0 1.178-.021c.32-.331.311-.86-.02-1.178l-1.199-1.159ZM4.025 14.61l1.2-1.159a.835.835 0 0 1 1.158 1.2L5.184 15.81a.83.83 0 0 1-1.178-.021.833.833 0 0 1 .02-1.178Z"
      />
    </mask>
  </svg>
);
