import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';

import { useQueryFilterTree } from '../../../../hooks/queries/use-query-filter-tree';
import { pluck } from '../../../../Utils';
import { FILTER_TYPES_ARRAY } from '../../../../constants';
import { useCategoryAppliedFilters } from '../../../../hooks/filters/use-category-applied-filters';
import { FilterItem } from './FilterItem';

export const FilterHeader = ({ filter: { displayHeader, items: availableCategoryFilters } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterIds = pluck('backendName', availableCategoryFilters);
  const { data, isFetching } = useQueryFilterTree(filterIds, isOpen);
  const { numberOfCategoryAppliedFilters, formattedCategoryAppliedFilters, handleRemoveCategoryFilters } =
    useCategoryAppliedFilters({
      availableCategoryFilters,
      categoryId: displayHeader,
    });

  const handleChange = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div data-testid="filter-header">
      <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        classes={{
          root: 'shadow-[0px_1px_3px_rgba(46,48,48,0.14)]',
        }}
        onChange={handleChange}
        expanded={isOpen}
      >
        <AccordionSummary
          id={displayHeader}
          aria-controls={displayHeader}
          expandIcon={<ExpandMoreIcon />}
          className={classnames('p-0 mx-4', {
            'border-b border-[#ddd] border-solid': isOpen,
          })}
        >
          <span>
            {displayHeader}
            {numberOfCategoryAppliedFilters ? (
              <Tooltip title={formattedCategoryAppliedFilters}>
                <span className="bg-[#ECF6FD] text-[#484848] rounded border-[#0288d1] border-[1px] text-xs px-1 ml-4 font-medium inline-flex items-center h-full">
                  {`${numberOfCategoryAppliedFilters} active`}{' '}
                  <CloseIcon
                    onClick={handleRemoveCategoryFilters}
                    className="fill-[#666] text-[18px] ml-1"
                  />
                </span>
              </Tooltip>
            ) : null}
          </span>
        </AccordionSummary>

        <AccordionDetails
          classes={{
            root: 'p-0 pl-4 pb-2',
          }}
        >
          {availableCategoryFilters.map((filter) => (
            <FilterItem
              key={filter.backendName}
              isFetching={isFetching}
              itemId={filter.backendName}
              itemName={filter.displayName}
              itemType={filter.__type__}
              itemUnit={filter.unit}
              filterItems={data?.[filter.backendName] ?? []}
              categoryFilterId={displayHeader}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

FilterHeader.propTypes = {
  filter: PropTypes.shape({
    displayHeader: PropTypes.string.isRequired,
    items: PropTypes.shape({
      backendName: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      unit: PropTypes.string,
      __type__: PropTypes.oneOf(FILTER_TYPES_ARRAY).isRequired,
    }),
  }),
};
