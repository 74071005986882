import React from 'react';
import { GridColumns, GridRenderCellParams, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';

import { CompanyCell } from './CompanyCell';
import { CommonCell } from './CommonCell';
import { RownumCell } from './RownumCell';
import { CheckboxHeader } from './CheckboxHeader';
import { CheckboxCell } from './CheckboxCell';
import { CompanyHeader } from './CompanyHeader';
import { ICollumnMapper, IMidtierTable } from '@/types';
import { prepareColumns } from '@/Utils/table';

export const SKELETON_COLUMN_FIELD_PREFIX = 'skeleton';

type TData = {
  tableRows: IMidtierTable[];
  columnMapper: ICollumnMapper[];
  currency: string;
  defaultColumns: string[];
};

export const getColumns = ({ tableRows, columnMapper, currency, defaultColumns }: TData) => {
  const groupedColumnMapper = _.keyBy(columnMapper, 'Backend Name');
  const columns = prepareColumns(columnMapper, defaultColumns)(tableRows) as string[];
  const isEmpty = _.isEmpty(columnMapper) || _.isEmpty(tableRows);

  if (isEmpty) {
    return _.range(10).map((__, i) => ({
      width: 200,
      field: `${SKELETON_COLUMN_FIELD_PREFIX}-${i.toString()}`,
      renderCell: () => (
        <Skeleton
          data-testid="table-skeleton-loader"
          className="w-full h-full bg-[#0000000f]"
        />
      ),
      renderHeader: () => (
        <Skeleton
          data-testid="table-skeleton-loader"
          className="w-full h-full bg-[#0000000f]"
        />
      ),
    }));
  }

  const COLUMNS = [
    {
      ...{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerName: 'Check' },
      renderHeader: () => <CheckboxHeader />,
      renderCell: (params) => <CheckboxCell {...params} />,
    },
    ...columns.map((column) => {
      const header = groupedColumnMapper[column]?.['Display Name'];

      if (column === 'rownum') {
        return {
          field: 'id',
          headerName: 'ID',
          width: 200,
          hide: true,
          renderCell: (params: GridRenderCellParams) => <RownumCell {...params} />,
        };
      } else if (column === 'self_firmo_name___') {
        return {
          field: column,
          headerName: header,
          renderHeader: () => <CompanyHeader headerName={header} />,
          width: 210,
          renderCell: (params: GridRenderCellParams) => <CompanyCell {...params} />,
        };
      } else if (column === 'self_financials_revenue___') {
        return {
          field: column,
          headerName: `${header} (${currency})`,
          width: 200,
          renderCell: (params: GridRenderCellParams) => (
            <CommonCell
              displayName={header}
              {...params}
            />
          ),
        };
      } // next hide any columns not in original list
      else if (!defaultColumns.includes(column)) {
        return {
          field: column,
          headerName: header,
          width: 200,
          hide: true,
          renderCell: (params: GridRenderCellParams) => (
            <CommonCell
              displayName={header}
              {...params}
            />
          ),
        };
      } else {
        return {
          field: column,
          headerName: header,
          width: 200,
          renderCell: (params: GridRenderCellParams) => (
            <CommonCell
              displayName={header}
              {...params}
            />
          ),
        };
      }
    }),
  ] as GridColumns;

  return COLUMNS;
};
