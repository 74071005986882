import styled from 'styled-components';
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';

export const Component = styled(DataGridPro)((props) => ({
  '.MuiIconButton-root': {
    pointerEvents: props.loading ? 'none' : 'auto',
  },
  '.MuiButton-textPrimary': {
    color: '#3f51b5',
    textTransform: 'uppercase',
  },
  '.MuiButton-textSizeSmall': {
    fontSize: '13px',
    textTransform: 'uppercase',
    padding: '4px 5px',
  },
  '.MuiButton-root': {
    fontWeight: '500',
    '&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.04)',
    },
  },
  '.MuiDataGrid-columnsPanelRow .MuiSwitch-root': {
    marginRight: '9px',
  },
  '.MuiTypography-body1': {
    fontSize: '16px',
  },
  '.MuiDataGrid-columnsPanelRow': {
    padding: '1px 8px 6px 7px',
  },
  '.MuiDataGrid-colCell': {
    pointerEvents: props.loading ? 'none' : 'auto',
  },
  '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
    fontWeight: '500',
  },
  '.MuiCheckbox-root': {
    color: '#DDD',
  },
  '.MuiCheckbox-root.Mui-checked': {
    color: '#0288d1 !important',
  },
  '.MuiPaper-elevation1': {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  '.MuiMenuItem-root': {
    fontSize: '16px',
    padding: '6px 16px 6px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  '.MuiListItemIcon-root': {
    minWidth: '56px !important',
  },
  '.MuiCircularProgress-root': {
    color: '#0288d1',
    zIndex: 999,
  },
  '.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer': {
    paddingLeft: '18px',
  },
  '[aria-label="Show Filters"]': {
    display: 'none',
  },
  '.MuiTablePagination-root': {
    color: 'black',
  },
  '.MuiTablePagination-displayedRows:not([id^=mui])::after': {
    // @ts-ignore
    content: props.countText,
    color: 'black',
  },
  '.MuiDataGrid-footerContainer': {
    justifyContent: 'flex-start',
  },
}));
