import React from 'react';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';

import { useModal } from '@/hooks/use-modal';
import { useMutationCreateSearchList } from '@/hooks/queries/search-list/use-mutation-create-search-list';
import { MODALS } from '@/constants';

const FORM_CONFIGURATION = {
  defaultValues: {
    title: '',
    description: '',
  },
};

export const CreateSearchListDialog = () => {
  const { isOpen, handleClose } = useModal(MODALS.CREATE_NEW_LIST);
  const { register, handleSubmit, reset } = useForm(FORM_CONFIGURATION);
  const mutation = useMutationCreateSearchList(reset);

  const onSubmit = (formData) => {
    mutation.mutate(formData);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: 'w-1/3 rounded-lg p-4' }}
    >
      <div className="flex justify-between items-center pb-4">
        <DialogTitle className="flex text-base font-bold p-0 text-[#484848]">
          Create new list
        </DialogTitle>

        <CloseIcon
          fontSize="small"
          className="cursor-pointer fill-[#484848]"
          onClick={handleClose}
        />
      </div>

      <DialogContent className="text-sm p-0">
        <span>A static list of companies that you can edit and share.</span>

        <form className="flex flex-col gap-4 pt-4">
          <div className='flex flex-col gap-2'>
            <span className="font-semibold">List name</span>
            <TextField
              data-testid="data-list-name"
              placeholder="Pick something topical..."
              {...register('title', { required: true })}
              variant="outlined"
              size="small"
            />
          </div>

          <div className='flex flex-col gap-2'>
            <span className="font-semibold">Description</span>
            <TextField
              data-testid="data-list-description"
              placeholder="Optional"
              {...register('description', { required: false })}
              variant="outlined"
              size="small"
              multiline
              rows={3}
            />
          </div>
        </form>

        <div className="flex justify-end mt-4">
          <Button
            variant="contained"
            className={classnames({
              'bg-cred-50': !mutation.isLoading,
            })}
            data-testid="create-list-button"
            onClick={handleSubmit(onSubmit)}
            endIcon={<CircularProgress
              className={classnames('text-white transition', {
                hidden: !mutation.isLoading,
              })}
              size={20}
            />}
            disabled={mutation.isLoading}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
