import React from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';

export const CompanyCell = ({ row, value }: GridRenderCellParams) => {
  const { handleOpen } = useModal(MODALS.COMPANY_PROFILE);
  const isSearchListLoading = useIsFetching({ queryKey: ['search-list'] }) > 0;
  const isTableLoading = useIsFetching({ queryKey: ['table-midtier'] }) > 0;
  const isLoading = isTableLoading || isSearchListLoading;

  const handleClick = () => {
    handleOpen({
      params: {
        row,
        value,
      },
    });
  };

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <span
      id="open-company-profile"
      className="w-full"
      onClick={handleClick}
    >
      <Tooltip title={value?.length > 20 ? value : ''}>
        <Button
          className="min-w-0 normal-case text-[#484848] px-2.5 py-2 w-full justify-start"
          id={value}
        >
          <span className="truncate">{value}</span>
        </Button>
      </Tooltip>
    </span>
  );
};
