import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import _ from 'lodash';

import { actions as filterActions } from '@/slices/filters';
import { actions as searchActions } from '@/slices/search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useScreener } from '@/hooks/use-screen';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { useQuerySearchListById } from '@/hooks/queries/search-list/use-query-search-list-by-id';
import { SearchControls } from '@/Components/Shared/SearchControls';
import ExportComponent from '@/Components/ExportComponent';
import { ScreenerTable } from '@/Components/Shared/ScreenerTable';
import Search from '@/Components/CompanyResearch/Search';
import { searchSubject } from '@/Utils/subjects';
import { FILTERS_ID, HISTORY_KEYS } from '@/constants';

const StyledGrid = styled(Grid)(() => ({
  '&.MuiGrid-root': {
    width: '100% !important',
    padding: '0px',
  },
  '&.MuiGrid-grid-xs-8': {
    flexGrow: '0',
    maxWidth: '82%',
    flexBasis: '82%',
  },
  '&.MuiGrid-grid-xs-4': {
    flexGrow: '0',
    maxWidth: '18%',
    flexBasis: '18%',
  },
}));

const SearchList = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { resetAll } = useScreener();
  const query = useQuerySearchListById(id);
  const queryTable = useQueryTable();
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);

  useEffect(() => {
    if (query.data) {
      dispatch(searchActions.setBainIds(query.data?.pinned_company_ids));
      dispatch(filterActions.setFilters(query.data?.filters));
    }
  }, [dispatch, query.data, queryTable.tableMidtierQuery.data]);

  useEffect(() => {
    if (query.isFetched) {
      queryTable.fetch({ fetchCount: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isFetched, bainIds]);

  useEffect(() => {
    const subscription = searchSubject.subscribe(async ({ text, isUniq = false }) => {
      if (text) {
        await dispatch(
          // @ts-ignore
          searchActions.fetchBainIds({
            searchText: _.trim(text),
            unique_company: isUniq,
          }),
        );
      }

      queryTable.fetch({ fetchCount: true });
    });

    return () => {
      subscription.unsubscribe();
      searchSubject.next({});
      resetAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.SCREENERS_HISTORY));
  }, [dispatch]);

  return (
    <>
      <Box
        mx={3}
        my={2}
      >
        <Box className="flex justify-between">
          <SearchControls
            isLoading={query.isFetching}
            data={query.data}
          />

          <Box
            color="#666666"
            flex="none"
          >
            <ExportComponent bainIds={bainIds} />
          </Box>
        </Box>
        <Box
          id={FILTERS_ID}
          sx={{ display: 'flex', label: 'Title here' }}
          mt={2}
        >
          {/* Change the repeat variable if you want more/less filters per row */}
          <StyledGrid
            container
            direction="row"
            spacing={1}
            className="grid-container"
          >
            {/* Company Search Text Box Starts Here */}
            <StyledGrid
              item
              xs={8}
              zeroMinWidth
            >
              <div className="flex flex-wrap flex-col mt-2">
                <div className="flex items-start">
                  <Search />
                </div>
              </div>
            </StyledGrid>
          </StyledGrid>
        </Box>

        <div className="mt-4">
          <ScreenerTable />
        </div>
      </Box>
    </>
  );
};

export default SearchList;
