import { useMemo, useState, useCallback } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  GridColumnOrderChangeParams,
  GridColumns,
  GridColumnResizeParams,
  GridDensity,
  GridState,
  GridFilterModel,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { useShallowSelector } from '../use-shallow-selector';
import { arrayMove } from '@/Utils/array-utils';
import { actions } from '@/slices/table';
import { EXTENDED_DEFAULT_COLUMNS } from '@/Components/CompanyResearch/ColumnsVisibilityPanel';
import { DEFAULT_PAGE_SIZE } from '@/constants';

const DEFAULT_DENSITY = 'standard';

interface UseCustomColumnsTransformationProps {
  columns: GridColumns;
}

export const useCustomColumnsTransformation = ({ columns }: UseCustomColumnsTransformationProps) => {
  const dispatch = useDispatch();
  const sortModel = useShallowSelector((state) => state.table.sortModel);
  const pageSize = useShallowSelector((state) => state.table.pageSize)
  const pageNumber = useShallowSelector((state) => state.table.pageNumber)
  const isConfidenceLevelChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);
  const [customColumnsOrder, setCustomColumnsOrder] = useState<Record<string, number>>({});
  const [customColumnsVisibility, setCustomColumnsVisibility] = useState<string[]>(EXTENDED_DEFAULT_COLUMNS);
  const [customColumnsWidth, setCustomColumnsWidth] = useState<Record<string, number>>({});
  const [customDensity, setCustomDensity] = useState<GridDensity>(DEFAULT_DENSITY);
  const [customTableFilters, setCustomTableFilters] = useState<GridFilterItem[]>([]);
  const [muiTableKey, setMuiTableKey] = useState(0);

  const transformedColumns = useMemo(() => {
    let orderedColumns = columns;

    for (const columnFieldName in customColumnsOrder) {
      const oldIndex = orderedColumns.findIndex((column) => column.field === columnFieldName);

      orderedColumns = arrayMove(orderedColumns, oldIndex, customColumnsOrder[columnFieldName]);
    }

    const visibleColumns = orderedColumns.map((column) => ({
      ...column,
      hide: !customColumnsVisibility.includes(column.field),
    }));

    return isEmpty(customColumnsWidth)
      ? visibleColumns
      : visibleColumns.map((column) => {
          if (column.field in customColumnsWidth) {
            return {
              ...column,
              width: customColumnsWidth[column.field],
            };
          }

          return column;
        });
  }, [columns, customColumnsOrder, customColumnsVisibility, customColumnsWidth]);

  const handleColumnOrderChange = useCallback((order: GridColumnOrderChangeParams) => {
    const { field, targetIndex } = order;

    setCustomColumnsOrder((prevState) => {
      return {
        ...prevState,
        [field]: targetIndex,
      };
    });
  }, []);

  const handleColumnVisibilityChange = useCallback((field: string, isVisible: boolean) => {
    setCustomColumnsVisibility((prevState) => {
      if (prevState.includes(field) && !isVisible) {
        return prevState.filter((columnField) => columnField !== field);
      }

      if (!prevState.includes(field) && isVisible) {
        return [...prevState, field];
      }

      return prevState;
    });
  }, []);

  const handleTableStateChange = useCallback((params: GridState) => {
    const density = params.density.value;

    setCustomDensity((prevDensity) => {
      if (prevDensity !== density) {
        return density;
      }

      return prevDensity;
    });
  }, []);

  const handleFilterModelChange = useCallback((params: GridFilterModel) => {
    const { items } = params;

    setCustomTableFilters(items);
  }, []);

  const handleColumnWidthChange = useCallback((params: GridColumnResizeParams) => {
    const { colDef, width } = params;

    setCustomColumnsWidth((prevState) => {
      return {
        ...prevState,
        [colDef.field]: width,
      };
    });
  }, []);

  const handleResetCustomSettings = useCallback(() => {
    setCustomColumnsOrder({});
    setCustomColumnsVisibility(EXTENDED_DEFAULT_COLUMNS);
    setCustomColumnsWidth({});
    setCustomDensity(DEFAULT_DENSITY);
    setCustomTableFilters([]);
    setMuiTableKey((prevKey) => prevKey + 1);

    dispatch(actions.resetTable());
  }, [dispatch]);

  const isTableModified =
    ![customColumnsOrder, customColumnsWidth, sortModel].every(isEmpty) ||
    !isEqual(EXTENDED_DEFAULT_COLUMNS, customColumnsVisibility) ||
    customDensity !== DEFAULT_DENSITY ||
    isConfidenceLevelChecked ||
    !!customTableFilters.length || pageSize !== DEFAULT_PAGE_SIZE || pageNumber !== 0

  return {
    transformedColumns,
    customDensity,
    isTableModified,
    muiTableKey,
    customTableFilters,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnWidthChange: handleColumnWidthChange,
    onResetColumnSettings: handleResetCustomSettings,
    onFilterModelChange: handleFilterModelChange,
    onStateChange: handleTableStateChange,
  };
};
