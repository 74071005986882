import React, { useEffect } from 'react';
import { HISTORY_KEYS } from '@/constants';
import { actions as searchActions } from '@/slices/search';
import { useDispatch } from 'react-redux';
import { useScreener } from '@/hooks/use-screen';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { CompanyResearch } from './CompanyResearch';
import { useSearchCompanies } from '../../hooks/use-search-companies'; // TODO: import order here is important (reason unknown), fix this

export const CompanyResearchDecorator = (props) => {
  const dispatch = useDispatch();
  const searchCompanies = useSearchCompanies();
  const { resetAll } = useScreener();
  const query = useQueryTable();

  const handleFetchTable = (fetchParams) => {
    query.fetch(fetchParams);
  };

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.CUSTOM_HISTORY));
  }, [dispatch]);

  return (
    <CompanyResearch
      {...props}
      searchCompanies={searchCompanies}
      handleFetchTable={handleFetchTable}
      resetAll={resetAll}
    />
  );
};
