import React from 'react';
import { useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import classNames from 'classnames';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as customScreenActions } from '@/slices/custom-screen';
import { CURRENCIES } from '@/constants';

interface IProps {
  showLabel: boolean;
  parentClassNames: string;
  selectClassNames: string;
}

export const SelectCurrency = ({ showLabel = false, parentClassNames = '', selectClassNames = '' }: IProps) => {
  const dispatch = useDispatch();
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const query = useQueryTable();

  const handleChange = (event: SelectChangeEvent<string>) => {
    dispatch(customScreenActions.setCurrency(event.target.value));

    query.fetch();
  };

  return (
    <FormControl className={parentClassNames}>
      {showLabel && <FormLabel className="text-xs mb-2">Set currency</FormLabel>}
      <Select
        id="select-currency"
        data-testid="select-currency"
        value={currency}
        onChange={handleChange}
        classes={{
          select: classNames(selectClassNames, 'text-[#2E3F4C]'),
        }}
      >
        {Object.entries(CURRENCIES).map(([key, val]) => (
          <MenuItem
            key={key}
            value={key}
          >
            {`${key} - ${val.label}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
