import React, { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { useSearchCompanies } from '@/hooks/use-search-companies';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { astToLogicalBlocks, QueryBlockDetails } from '@/Utils/pegjs/astTreeUtils';
import { AstNode, Position } from '@/types/ast-tree-models';
import { actions } from '@/slices/search';
import { MODALS, SEARCH_EVENTS } from '@/constants';

export const SavedQueries = () => {
  const dispatch = useDispatch();
  const [hasChange, setHasChange] = useState(false);
  const searchCompanies = useSearchCompanies();
  const { handleOpen: openAdvancedSearchModal } = useModal(MODALS.ADVANCED_SEARCH);
  const searchQueryTree = useShallowSelector(({ search }) => search.searchQueryTree);
  const searchText = useShallowSelector((state) => state.search.searchText);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const { isError } = useShallowSelector(({ search }) => search.bainIds);

  let key = 0;

  useEffect(() => {
    if (hasChange) {
      searchCompanies({ searchText, eventName: SEARCH_EVENTS.REMOVE_QUERY });
      setHasChange(false);
    }
  }, [queryType, searchCompanies, searchText, hasChange]);

  const handleRemove =
    (position: Position): MouseEventHandler =>
    (event) => {
      event.stopPropagation();
      dispatch(actions.removeSearchQuery(position));
      setHasChange(true);
    };

  const handleClick = () => {
    openAdvancedSearchModal();
  };

  const getQueryBlock = ({ term, isKeyWord, isKeyChar, position }: QueryBlockDetails): ReactNode => {
    return (
      <div
        key={key++}
        onClick={handleClick}
        className={classnames('w-fit flex items-center bg-[#ebf9f2] p-1 rounded text-xs cursor-pointer', {
          'bg-[#eae6ff]': isKeyWord,
          'bg-[#e7cede]': isKeyChar,
        })}
      >
        <p className="text-left">{term}</p>

        {!isKeyChar && !isKeyWord && (
          <IconButton
            className="w-4 h-4 ml-1"
            onClick={handleRemove(position as Position)}
          >
            <CloseIcon className="w-4	h-4" />
          </IconButton>
        )}
      </div>
    );
  };

  const astTreeToView = (node: AstNode) => {
    const blocks = astToLogicalBlocks(node).filter(({ term }) => !!term);

    return blocks.map((block) => getQueryBlock(block));
  };

  const showSavedQueries = !isError && !isEmpty(searchQueryTree);

  return showSavedQueries ? (
    <div
      data-testid="search-saved-queries"
      className="flex flex-wrap gap-3 mt-4 ml-2"
    >
      {astTreeToView(searchQueryTree)}
    </div>
  ) : null;
};
