import React, { Component } from 'react';
import { Box, Grid } from '@mui/material';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ExportComponent from '../ExportComponent';
import ErrorDialog from '../Shared/ErrorDialog';
import { actions as searchActions } from '../../slices/search';
import { searchSubject } from '../../Utils/subjects';
import Search from './Search';

import { ScreenerTable } from '@/Components/Shared/ScreenerTable';
import { FILTERS_ID } from '@/constants';
import '../../App.css';

const StyledGrid = styled(Grid)(({ groupLength }) => ({
  '&.MuiGrid-root': {
    width: '100% !important',
    padding: '0px',
  },
  '&.MuiGrid-grid-xs-8': {
    flexGrow: '0',
    maxWidth: '82%',
    flexBasis: '82%',
  },
  '&.MuiGrid-grid-xs-4': {
    flexGrow: '0',
    maxWidth: '18%',
    flexBasis: '18%',
  },
}));

class CompanyResearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCompanies: this.props.searchCompanies,
      throwError: false,
      isExportTypeOpen: false,
    };
    this.search$ = searchSubject.subscribe(this.searchObserver);

    this.exportDropdownRef = React.createRef();
  }

  componentWillUnmount() {
    this.search$.unsubscribe();
    searchSubject.next({});
    this.props.resetAll();
  }

  searchObserver = async ({ text, isUniq = false }) => {
    if (text) {
      await this.props.fetchBainIds({
        searchText: _.trim(text),
        unique_company: isUniq,
      });
    }

    this.props.handleFetchTable({ fetchCount: true });
  };

  render() {
    const { throwError } = this.state;
    const { bainIds } = this.props;

    return (
      <>
        <ErrorDialog throwError={throwError} />

        <Box
          mx={3}
          my={2}
        >
          <Box
            id={FILTERS_ID}
            sx={{ display: 'flex', label: 'Title here' }}
            mt={2}
          >
            {/* Company Search Text Box Starts Here */}
            <StyledGrid
              spacing={1}
              container
              item
              xs={8}
              zeroMinWidth
              className="w-full"
            >
              <div className="flex mt-2 w-full">
                <Search />
                <ExportComponent
                  bainIds={bainIds}
                  isCompanyProfile={false}
                />
              </div>
            </StyledGrid>
          </Box>

          <div className="mt-4">
            <ScreenerTable />
          </div>
        </Box>
      </>
    );
  }
}

export const CompanyResearch = connect(
  (state) => ({
    bainIds: state.search.bainIds.data,
    searchText: state.search.searchText,
  }),
  {
    fetchBainIds: searchActions.fetchBainIds,
  },
)(CompanyResearchComponent);
