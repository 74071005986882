import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { actions, QueryType } from '..';
import { queryElasticSearchBainIds } from '@/Api/search-api.service';
import { getFiltersForElasticSearch, normalizeFilters } from '@/Utils/filters';
import { TRootState } from '@/store';
import { Nullish } from '@/types';
import { DEFAULT_ERROR_MESSAGE } from '@/constants';

type TData = { searchText: string; unique_company: Nullish<boolean> };

export const fetchBainIds = createAsyncThunk('search/baindIds', async (payload: TData, { dispatch, getState }) => {
  dispatch(actions.setIsError({ value: false, status: null, message: null }));

  try {
    const {
      filters,
      customScreen: { currency },
      search: { queryType },
    } = getState() as TRootState;

    const isKeywordQuery = [QueryType.KEYWORD, QueryType.SMART].includes(queryType);

    dispatch(actions.setIsLoading(true));

    const normalizedFilters = { ...filters, otherFilters: normalizeFilters(filters.otherFilters) };

    const result = await queryElasticSearchBainIds({
      company_search: payload.searchText,
      filters: getFiltersForElasticSearch(normalizedFilters),
      include_null: filters.includedNullList,
      unique_company: payload.unique_company,
      lucene_query: isKeywordQuery,
      currency,
    });

    dispatch(actions.setTotalBainIds(result.id_count));
    dispatch(actions.setBainIds(result.bain_id));

    if (isKeywordQuery) {
      dispatch(actions.setSearchText(''));
      dispatch(actions.setAdvancedSearchText(''));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const hasMessage = _.hasIn(error, ['response', 'data', 'user_friendly_error', 0]);
    const responseMessage = error.response.data['user_friendly_error'][0];
    const message = hasMessage ? responseMessage : DEFAULT_ERROR_MESSAGE;

    dispatch(
      actions.setIsError({
        value: true,
        status: error.response.status,
        message,
      }),
    );
  } finally {
    dispatch(actions.setIsLoading(false));
  }
});
