export const ONE_DAY_IN_MILLISECONDS = 8.64e+7;
export const TODAY_LABEL = 'Today';
export const YESTERDAY_LABEL = 'Yesterday';

export const getLocalTimeFromTimestamp = (timestamp) => new Date(timestamp)
  .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

export const getLocalDateFromTimestamp = (timestamp) => new Date(timestamp).toLocaleDateString([], {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
});

export const getYesterdayLocalDate = () => getLocalDateFromTimestamp(Date.now() - ONE_DAY_IN_MILLISECONDS);

export const getTodayLocalDate = () => getLocalDateFromTimestamp(Date.now());

export const getDateLabelGenerator = (today, yesterday) => (date) => {
  if (date === today) return TODAY_LABEL
  if (date === yesterday) return YESTERDAY_LABEL

  return date
}