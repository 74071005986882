import { useQuery } from '@tanstack/react-query';

import { getSearchListById } from '@/services/api';

export const useQuerySearchListById = (id: string) => {
  return useQuery(['search-list', id], () => getSearchListById(id), {
    enabled: !!id,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
