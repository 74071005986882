import React from 'react';
import Checkbox from '@mui/material/Checkbox';

import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const CheckboxHeader = () => {
  const isSelectedAll = useShallowSelector((state) => state.customScreen.isSelectedAll);

  return (
    <Checkbox
      checked={isSelectedAll}
      disableRipple
      disableFocusRipple
    />
  );
};
