import React from 'react';
import PropTypes from 'prop-types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { actions } from '@/slices/table';
import { Chevron } from '@/Components/Icons/Chevron';

export const Pagination = ({ handlePageChange }) => {
  const pageSize = useShallowSelector((state) => state.table.pageSize);
  const pageNumber = useShallowSelector((state) => state.table.pageNumber);
  const dispatch = useDispatch();

  const goToPageInputHandler = (e) => {
    if (![1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 'Enter'].includes(e.key)) return;

    if (e.key === 'Enter') {
      handlePageChange(Number(e.target.value) - 1);
    }
  };

  const goToPreviousPageHandler = () => {
    if (Number(pageNumber) < 1) return;
    handlePageChange(Number(pageNumber) - 1);
  };

  return (
    <div className="flex items-center gap-x-6">
      <FormControl
        variant="standard"
        className="flex items-center, mx-2 width-5"
      >
        <div className="flex items-center gap-x-2.5">
          <span className="text-xs color-[#666666]">Show:</span>
          <Select
            labelId="pageSize"
            value={pageSize}
            onChange={(e) => {
              dispatch(actions.setPageSize(e.target.value));
              handlePageChange(pageNumber)
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </div>
      </FormControl>
      <div className="flex items-center gap-x-2.5">
        <button onClick={goToPreviousPageHandler}>
          <Chevron className="rotate-180" />
        </button>
        <span>{pageNumber + 1}</span>
        <button onClick={() => handlePageChange(Number(pageNumber) + 1)}>
          <Chevron />
        </button>
      </div>
      <div className="flex items-center gap-x-2.5">
        <span>Go to</span>

        <input
          className="text-center rounded w-10 h-6 border border-solid border-color-[#DDDDDD]"
          type="text"
          onKeyDown={goToPageInputHandler}
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  handlePageChange: PropTypes.func,
};
