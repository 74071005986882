import React, { useRef } from 'react';
import Box from '@mui/material/Box';

import { ExportDropdown } from './CompanyResearch/ExportDropdown';
import { SearchListControls } from '@/Components/Shared/SearchListControls';

import './ExportComponent.css';

const ExportComponent = ({ bainIds, withoutCount = false, isCompanyProfile = false, companyProfileId }) => {
  const exportDropdownRef = useRef();

  return (
    <div
      className="ml-auto flex justify-end h-[36px]"
      data-testid="exportComponent"
    >
      {!withoutCount && <SearchListControls />}

      <Box ref={exportDropdownRef}>
        <ExportDropdown
          skipComparison={withoutCount}
          bainIds={bainIds}
          ref={exportDropdownRef}
          isCompanyProfile={isCompanyProfile}
          companyProfileId={companyProfileId}
        />
      </Box>
    </div>
  );
};

export default ExportComponent;
