import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import { NotificationMessage } from '../../Shared/Notifications/NotificationMessage';
import { getUserEmail } from '../../../Api/user-api.service';
import { downloadCompanyOverview } from '../../../Api/company-overview-api.service';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { actions as modalActions } from '@/slices/modal';

import { useShallowSelector } from '@/hooks/use-shallow-selector';

const MAX_OVERVIEW_COMPANIES = 5;

export const ExportDropdown = forwardRef(
  ({ onQuerySelect, onDropdownToggle, bainIds, skipComparison, isCompanyProfile, companyProfileId }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { handleOpen: handleExportModalOpen } = useModal(MODALS.EXPORT);
    const selectedCompanies = useShallowSelector((state) => state.customScreen.selectedCompanies);

    const currency = useShallowSelector((state) => state.customScreen.currency);
    const caseCodeFormData = useShallowSelector((state) => state.caseCode.formData);
    const isOpened = !!anchorEl;
    const isTooMuchForOverview = selectedCompanies.length > MAX_OVERVIEW_COMPANIES;
    const areCompaniesSelected = selectedCompanies.length > 0;
    const isComparable = selectedCompanies.length >= 2;

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const openDropdown = () => {
      setAnchorEl(ref?.current);
      onDropdownToggle?.(true);
    };

    const handleDropdownClose = (query) => {
      setAnchorEl(null);

      if (query && onQuerySelect) {
        onQuerySelect(query);
      }

      onDropdownToggle?.(false);
    };

    const handleCompanyOverview = () => {
      dispatch(
        modalActions.openModal({ id: isTooMuchForOverview ? MODALS.TOO_MANY_COMPANIES : MODALS.COMPANY_OVERVIEW }),
      );
    };

    const handleCompanyComparison = () => {
      dispatch(
        modalActions.openModal({ id: isTooMuchForOverview ? MODALS.TOO_MANY_COMPANIES : MODALS.COMPANY_OVERVIEW }),
      );
    };

    const items = [
      {
        label: 'Overview',
        disabled: !areCompaniesSelected && !isCompanyProfile,
        description: 'A single company overview including key facts, financials & sources',
        onClick: async () => {
          if (!isCompanyProfile) {
            handleCompanyOverview();
          } else {
            const email = await getUserEmail();

            try {
              await downloadCompanyOverview(companyProfileId, [], currency, email, caseCodeFormData);
              enqueueSnackbar(
                <NotificationMessage
                  title="Success! You will receive an email with a link to your export."
                  description="Exports may take upwards of 10 minutes based on the size of the data.
            Please wait before re-attempting to export."
                />,
                { variant: 'success' },
              );
            } catch (err) {
              enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
                variant: 'error',
              });
            }
          }

          handleDropdownClose();
        },
      },

      ...(!skipComparison
        ? [
            {
              label: 'Comparison',
              disabled: !isComparable,
              description: ' Choose a target from your selection and compare financial data',
              onClick: () => {
                handleCompanyComparison();
                handleDropdownClose();
              },
            },
          ]
        : []),
      {
        label: 'Data',
        disabled: false,
        description: 'Export a spreadsheet with all company data',
        onClick: () => {
          handleExportModalOpen({ bainIds });
          handleDropdownClose();
        },
      },
    ];

    return (
      <>
        <Button
          data-testid="search-type-dropdown"
          aria-controls={isOpened ? 'history-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpened ? 'true' : undefined}
          disableElevation
          onClick={openDropdown}
          className={classnames(
            'ml-[1px] py-2 px-3 border border-solid font-bold font-graphik text-white text-xs h-[36px]',
            {
              'border-cred-900 bg-cred-900': !areCompaniesSelected || isCompanyProfile,
              'border-bluegray-900 bg-bluegray-900 rounded-l-none': areCompaniesSelected && !isCompanyProfile,
              'rounded-l': skipComparison,
            },
          )}
        >
          <span>Export</span>
          <KeyboardArrowDownIcon className={classnames('text-xl transition ml-1', { 'rotate-180': isOpened })} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpened}
          onClose={handleDropdownClose}
          classes={{
            paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
          }}
        >
          {items.map((item, idx) => (
            <MenuItem
              key={idx}
              onClick={item.onClick}
              disabled={item.disabled}
              sx={{ width: '492px' }}
            >
              <Box className="flex flex-col">
                <Typography
                  className="font-bold"
                  variant="inherit"
                  noWrap
                >
                  {item.label}
                </Typography>
                <span className="text-xs text-[#666666]">{item.description}</span>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);

ExportDropdown.displayName = 'ExportDropdown';
