import {
  reduce,

  isEmpty,
  cond,
  matches,
  stubTrue,
  constant,
  isNull,
  cloneDeep,
  isNil,
  isUndefined,
} from 'lodash';

import { HIDE_FILTERS_COUNT, FILTER_TYPES } from '@/constants';

const filtersLabelMapper = {
  Y: 'Yes',
  N: 'No',
  [null]: 'Unclassified',
  UNKNOWN: 'Missing',
  UNOWNED: 'Unowned',
};

export const getFilterValueLabel = (value) => filtersLabelMapper[value] ?? value;

export const getCheckedTreeFilters = (treeFilters) => {
  const filtered = {};

  Object.entries(treeFilters).forEach(([key, value]) => {
    filtered[key] = value
      .filter((val) => {
        const parsed = JSON.parse(val);

        if (parsed.value === 'All') return false;

        return !parsed.hasParentNodeChecked && !parsed.halfChecked;
      })
      .map((item) => {
        const parsed = JSON.parse(item);

        return parsed.path.reduce((acc, pathItem) => ({ ...acc, ...pathItem }));
      });
  });

  return filtered;
};

export const getFilters = ({ treeFilters, otherFilters }) => ({
  ...reduce(
    {
      ...getCheckedTreeFilters(treeFilters),
      ...otherFilters,
    },
    (acc, item, id) => {
      if (!isEmpty(item)) {
        acc[id] = item;
      }

      return acc;
    },
    {},
  ),
});

export const getFiltersForElasticSearch = (filters) =>
  reduce(getFilters(filters), (acc, item, id) => acc.concat({ [id]: item }), []);

export const getAvailableFilterIdsWithType = (availableFilters) =>
  availableFilters.reduce((acc, filter) => {
    filter.items.forEach(({ backendName, __type__ }) => {
      acc[backendName] = __type__;
    });

    return acc;
  }, {});

export const getFilterIdsByType = (availableFilters, filters, type) => {
  const availableFilterIdsWithType = getAvailableFilterIdsWithType(availableFilters);

  return reduce(
    filters,
    (acc, item, id) => {
      if (availableFilterIdsWithType[id] === type) {
        acc.push(id);
      }

      return acc;
    },
    [],
  );
};

export const getCurrentFiltersToDisplay = (filters, columnMapper) => {
  const keyObj = filters?.otherFilters ?? {};
  const filtersToDisplay = []; // e.g { label: 'Location', value: '' }

  Object.values(filters?.treeFilters ?? {}).forEach((values) => {
    filtersToDisplay.push(...getTreeFiltersStr(columnMapper, values));
  });

  Object.entries(keyObj).forEach(([beKey, value]) => {
    const info = getColumnInfoBeKey(columnMapper, beKey);

    filtersToDisplay.push({
      label: info?.['Display Name'],
      value: getFilterValueDisplayStr(value, info?.['NUMERIC_TYPE'] === '%'),
    });
  });

  if (filters?.includedNullList?.length > 0) {
    filtersToDisplay.push({
      label: 'Filters including null values',
      value: filters.includedNullList.map((x) => getColumnInfoBeKey(columnMapper, x)?.['Display Name']).join(', '),
    });
  }

  return filtersToDisplay;
};

const getColumnInfoBeKey = (columnMapper, beKey) => columnMapper.find((x) => x['Backend Name'] === beKey);

const getColumnDisplayLabel = (columnMapper, beKey) => getColumnInfoBeKey(columnMapper, beKey)?.['Display Name'];

const getFilterValueDisplayStr = (value, isPercentage) => {
  if (Array.isArray(value)) {
    return value
      .map((x) => {
        if (x === 'N') return '"No"';
        if (x === 'Y') return '"Yes"';

        return `"${x}"`;
      })
      .join(', ');
  }

  if (value.min?.toString() || value.max?.toString()) {
    let formattedNumericValue = isNil(value.min)
      ? ''
      : `"min": "${value.min.toLocaleString('en')}${isPercentage ? '$' : ''}", `;

    formattedNumericValue += isNil(value.max)
      ? ''
      : `"max": "${value.max.toLocaleString('en')}${isPercentage ? '$' : ''}"`;

    return formattedNumericValue;
  }

  if (isNull(value.min) && isNull(value.max)) {
    return 'exclude missing values';
  }

  return value;
};

const getTreeFiltersStr = (columnMapper, values) => {
  const resultsObj = {};

  values.forEach((x) => {
    const { key, value } = JSON.parse(x);

    if (resultsObj[key]) {
      resultsObj[key].values.push(value);
    } else {
      resultsObj[key] = {
        label: getColumnDisplayLabel(columnMapper, key),
        values: [value],
      };
    }
  });

  return Object.values(resultsObj).map(({ label, values }) => ({ label, value: values.join(', ') }));
};

export const getCategoricalFilterValue = (value) => (value === null ? 'NULL' : value);

export const getParentNode = (key, tree) => {
  let parentNode;

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children) {
      if (node.children.some((item) => item?.key === key)) {
        parentNode = node;
      } else if (getParentNode(key, node.children)) {
        parentNode = getParentNode(key, node.children);
      }
    }
  }

  return parentNode;
};

export const prepareFilterTree = ({ data, nodeId = '', categoryFilterId, Icon, path = [] }) =>
  data.map((node, idx) => {
    const id = nodeId ? `${nodeId}-${idx}` : `${idx}`;

    const joinedPath = node.value !== 'All' ? [...path, { [node._backend]: node.value }] : path;

    return {
      key: JSON.stringify({
        id,
        key: node._backend,
        value: node.value,
        description: node.meta?.description,
        hasChildren: !!node?.children,
        categoryFilterId: categoryFilterId ?? node.categoryFilterId,
        hasParentNodeChecked: false,
        halfChecked: false,
        path: joinedPath,
      }),
      title: `${getFilterValueLabel(node.value)}${HIDE_FILTERS_COUNT ? '' : ` (${node.active.toLocaleString('en')})`}`,
      description: node.meta?.description,
      icon: Icon,
      ...(node?.children && {
        children: prepareFilterTree({
          nodeId: id,
          data: node.children,
          categoryFilterId: categoryFilterId ?? node.categoryFilterId,
          Icon,
          path: joinedPath,
        }),
      }),
    };
  });

const serializeFilterByType = cond([
  [matches({ __type__: FILTER_TYPES.TREE }), ({ value }) => ({ key: 'treeFilters', value })],
  [
    matches({ __type__: FILTER_TYPES.CATEGORICAL }),
    ({ value }) => ({ key: 'otherFilters', value: value.map((item) => (isNull(item) ? 'NULL' : item)) }),
  ],
  [matches({ __type__: FILTER_TYPES.NUMERICAL }), ({ value }) => ({ key: 'otherFilters', value })],
  [stubTrue, constant(null)],
]);

export const getFilterState = (filters) =>
  filters.reduce(
    (acc, filterItem) => {
      filterItem.items.forEach((item) => {
        const serializedFilter = serializeFilterByType(item);

        if (serializedFilter) {
          acc[serializedFilter.key][item.backendName] = serializedFilter.value;
        }

        acc.includedNullList.push(item.backendName);
      });

      return acc;
    },
    {
      treeFilters: {},
      otherFilters: {},
      includedNullList: [],
    },
  );

export const normalizeFilters = (filters) => {
  const filtersCopy = cloneDeep(filters);

  Object.keys(filtersCopy).forEach((key) => {
    const filterFiled = filtersCopy[key];
    const isNumericalFilter =
      !Array.isArray(filterFiled) && (!isUndefined(filterFiled.min) || !isUndefined(filterFiled.max));

    if (isNumericalFilter) {
      filtersCopy[key] = {
        ...(!isUndefined(filterFiled.min) && { min: filterFiled.min }),
        ...(!isUndefined(filterFiled.max) && { max: filterFiled.max }),
      };
    }
  });

  return filtersCopy;
};
