import { groupBy, get } from 'lodash';

export const pluck = (path: string, data: unknown[]) => data.map((item) => get(item, path));

export function groupAndSortList<T>(list: T[] = [], orderList: string[] = [], key = 'id'): [string, T[]][] {
  const sectionsList = Object.entries(groupBy(list, key));

  sectionsList?.sort((a, b) => orderList.indexOf(a[0]) - orderList.indexOf(b[0]));

  return sectionsList;
}
