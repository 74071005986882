import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { clearSearchSubject } from '@/Utils/subjects';
import { useQueryColumnMapper } from '@/hooks/queries/use-query-column-mapper';
import { useQueryTable } from '@/hooks/queries/use-query-table';
import { useQuerySearchLists } from '@/hooks/queries/search-list/use-query-search-lists';

interface IProps {
  children: ReactNode;
}

export const Main = ({ children }: IProps) => {
  useQueryColumnMapper();
  useQuerySearchLists();

  const queryClient = useQueryClient();
  const location = useLocation();
  const query = useQueryTable();

  useEffect(() => {
    query.fetch({ fetchCount: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = clearSearchSubject.subscribe(() => {
      queryClient.invalidateQueries({
        queryKey: ['filter-tree'],
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['filter-tree'],
    });
  }, [location, queryClient]);

  return <main data-testid="appComponent">{children}</main>;
};
