import _ from 'lodash';

import { ICollumnMapper, IMidtierTable, Nullish } from '@/types';
import { FIXED_ELASTIC_SEARCH_OUTPUT } from '@/constants';

type TData = {
  isElasticSearch: boolean;
  bainIds: string[];
  totalBainIds: Nullish<number>;
  tableCount: number;
};

export const getRows = (rows: IMidtierTable[]) =>
  _.isEmpty(rows)
    ? _.range(30).map((__, id) => ({ bain_id: id, id }))
    : rows.reduce<IMidtierTable[]>((acc, { bain_id, rownum, ...other }) => {
        if (bain_id) {
          acc.push({
            bain_id,
            id: rownum,
            ...other,
          });
        }

        return acc;
      }, []);

const formatNumber = (num: number) => Intl.NumberFormat('en-US').format(num);

export const getCountText = ({ isElasticSearch, bainIds, totalBainIds, tableCount }: TData) => {
  const isElasticEmptyResponse =
    isElasticSearch && bainIds.length === 1 && bainIds[0] === '-1' && !_.isNil(totalBainIds);

  if (isElasticSearch && !_.isNil(totalBainIds) && totalBainIds > FIXED_ELASTIC_SEARCH_OUTPUT) {
    return `~${formatNumber(totalBainIds)}`;
  } else {
    return `${formatNumber(isElasticEmptyResponse ? totalBainIds : tableCount)}`;
  }
};

export const prepareColumns = (columnMapper: ICollumnMapper[], columnSortOrder: string[]) =>
  _.flow([
    (data: IMidtierTable[]) => _.keys(data[0] ?? []),
    (data: string[]) => {
      const columnMapperKeys = _.map(columnMapper, 'Backend Name');

      return data.filter((column) => columnMapperKeys.includes(column) || column === 'rownum');
    },
    (data: string[]) => [...columnSortOrder, ..._.remove(data, (col) => !_.includes(columnSortOrder, col))],
  ]);
