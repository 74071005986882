import React from 'react';

export const IconHelp = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08335 7.91675C7.08335 6.30841 8.39169 5.00008 10 5.00008C11.6084 5.00008 12.9167 6.30841 12.9167 7.91675C12.9167 9.23341 12.0334 10.3359 10.8334 10.6976V11.6667C10.8334 12.1276 10.4609 12.5001 10 12.5001C9.53919 12.5001 9.16669 12.1276 9.16669 11.6667V10.0001C9.16669 9.53925 9.53919 9.16675 10 9.16675C10.6892 9.16675 11.25 8.60591 11.25 7.91675C11.25 7.22758 10.6892 6.66675 10 6.66675C9.31085 6.66675 8.75002 7.22758 8.75002 7.91675C8.75002 8.37758 8.37752 8.75008 7.91669 8.75008C7.45585 8.75008 7.08335 8.37758 7.08335 7.91675ZM9.16669 14.1667C9.16669 13.7059 9.53919 13.3334 10 13.3334C10.4609 13.3334 10.8334 13.7059 10.8334 14.1667C10.8334 14.6276 10.4609 15.0001 10 15.0001C9.53919 15.0001 9.16669 14.6276 9.16669 14.1667ZM10 16.6667C6.32419 16.6667 3.33335 13.6759 3.33335 10.0001C3.33335 6.32425 6.32419 3.33341 10 3.33341C13.6759 3.33341 16.6667 6.32425 16.6667 10.0001C16.6667 13.6759 13.6759 16.6667 10 16.6667ZM10 1.66675C5.40502 1.66675 1.66669 5.40508 1.66669 10.0001C1.66669 14.5951 5.40502 18.3334 10 18.3334C14.595 18.3334 18.3334 14.5951 18.3334 10.0001C18.3334 5.40508 14.595 1.66675 10 1.66675Z"
      fill="#2E3F4C"
    />
    <mask
      id="mask0_2153_26102"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="18"
      height="18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08335 7.91675C7.08335 6.30841 8.39169 5.00008 10 5.00008C11.6084 5.00008 12.9167 6.30841 12.9167 7.91675C12.9167 9.23341 12.0334 10.3359 10.8334 10.6976V11.6667C10.8334 12.1276 10.4609 12.5001 10 12.5001C9.53919 12.5001 9.16669 12.1276 9.16669 11.6667V10.0001C9.16669 9.53925 9.53919 9.16675 10 9.16675C10.6892 9.16675 11.25 8.60591 11.25 7.91675C11.25 7.22758 10.6892 6.66675 10 6.66675C9.31085 6.66675 8.75002 7.22758 8.75002 7.91675C8.75002 8.37758 8.37752 8.75008 7.91669 8.75008C7.45585 8.75008 7.08335 8.37758 7.08335 7.91675ZM9.16669 14.1667C9.16669 13.7059 9.53919 13.3334 10 13.3334C10.4609 13.3334 10.8334 13.7059 10.8334 14.1667C10.8334 14.6276 10.4609 15.0001 10 15.0001C9.53919 15.0001 9.16669 14.6276 9.16669 14.1667ZM10 16.6667C6.32419 16.6667 3.33335 13.6759 3.33335 10.0001C3.33335 6.32425 6.32419 3.33341 10 3.33341C13.6759 3.33341 16.6667 6.32425 16.6667 10.0001C16.6667 13.6759 13.6759 16.6667 10 16.6667ZM10 1.66675C5.40502 1.66675 1.66669 5.40508 1.66669 10.0001C1.66669 14.5951 5.40502 18.3334 10 18.3334C14.595 18.3334 18.3334 14.5951 18.3334 10.0001C18.3334 5.40508 14.595 1.66675 10 1.66675Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2153_26102)"></g>
  </svg>
);
