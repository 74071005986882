import React from 'react';

export const IconEnvelope = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8334 14.9999H4.16669C3.70752 14.9999 3.33335 14.6266 3.33335 14.1666V6.04159L9.50002 10.6666C9.64835 10.7783 9.82419 10.8333 10 10.8333C10.1759 10.8333 10.3517 10.7783 10.5 10.6666L16.6667 6.04159V14.1666C16.6667 14.6266 16.2925 14.9999 15.8334 14.9999ZM15.2775 4.99992L10 8.95825L4.72252 4.99992H15.2775ZM15.8334 3.33325H4.16669C2.78835 3.33325 1.66669 4.45492 1.66669 5.83325V14.1666C1.66669 15.5449 2.78835 16.6666 4.16669 16.6666H15.8334C17.2117 16.6666 18.3334 15.5449 18.3334 14.1666V5.83325C18.3334 4.45492 17.2117 3.33325 15.8334 3.33325Z"
      fill="#2E3F4C"
    />
    <mask
      id="mask0_2153_26105"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="1"
      y="3"
      width="18"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8334 14.9999H4.16669C3.70752 14.9999 3.33335 14.6266 3.33335 14.1666V6.04159L9.50002 10.6666C9.64835 10.7783 9.82419 10.8333 10 10.8333C10.1759 10.8333 10.3517 10.7783 10.5 10.6666L16.6667 6.04159V14.1666C16.6667 14.6266 16.2925 14.9999 15.8334 14.9999ZM15.2775 4.99992L10 8.95825L4.72252 4.99992H15.2775ZM15.8334 3.33325H4.16669C2.78835 3.33325 1.66669 4.45492 1.66669 5.83325V14.1666C1.66669 15.5449 2.78835 16.6666 4.16669 16.6666H15.8334C17.2117 16.6666 18.3334 15.5449 18.3334 14.1666V5.83325C18.3334 4.45492 17.2117 3.33325 15.8334 3.33325Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2153_26105)"></g>
  </svg>
);
