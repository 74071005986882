import React, { ComponentType, useEffect } from 'react';
import { Switch, Route, RouteProps, Redirect, RouteComponentProps } from 'react-router-dom';

import { useLocalStorage } from 'usehooks-ts';
import SearchLists from './search-lists';
import SearchList from './search-list';
import awsService from '@/awsService';
import Login from '@/Components/login';
import { CompanyResearchDecorator } from '@/Components/CompanyResearch/CompanyResearch.decorator';
import HomePage from '@/Components/homePage';
import NotFound from '@/Components/NotFound';
import SwaggerUI from '@/Components/Swagger/SwaggerUI';
import { LOCAL_STORAGE_KEYS } from '@/constants';

interface IProps extends RouteProps {
  component: ComponentType<RouteComponentProps> | ComponentType;
}

const GuardedRoute = ({ component: Component, ...rest }: IProps) => {
  const isAuth = awsService.getAuthenticationStatus();
  const [, setRedirectUrl] = useLocalStorage<string>(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');

  useEffect(() => {
    if (!isAuth) {
      setRedirectUrl(`${rest.location?.pathname}${rest.location?.search ?? ''}`);
    }
  }, [isAuth, rest.location?.pathname, rest.location?.search, setRedirectUrl]);

  return (
    <Route
      {...rest}
      render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default function Routes() {
  return (
    <Switch>
      <Route
        path="/"
        component={Login}
        exact
      />
      <GuardedRoute
        path="/search-list/:id"
        component={SearchList}
      />
      <GuardedRoute
        path="/customScreen"
        component={CompanyResearchDecorator}
      />
      <GuardedRoute
        path="/search-lists"
        component={SearchLists}
      />
      <GuardedRoute
        path="/homePage"
        component={HomePage}
      />
      <GuardedRoute
        path="/swagger"
        component={SwaggerUI}
      />
      <Route component={NotFound} />
    </Switch>
  );
}
