import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

import { ISearchList } from '@/types';
import { useMenu } from '@/hooks/use-menu';
import { SearchListMenu } from '@/Components/Shared/SearchListMenu';

interface IProps {
  searchList: ISearchList;
}

export const SearchCard = ({ searchList }: IProps) => {
  const history = useHistory();
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const handleRoute = () => {
    if (!isOpen) {
      history.push(`/search-list/${searchList.list_id}`);
    }
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    handleClick(event);
  };

  return (
    <div
      onClick={handleRoute}
      className="flex flex-col rounded-lg border border-[#ddd] p-4 gap-3 shadow-[0_1px_3px_0px_rgba(46,48,48,0.14)] cursor-pointer"
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <FormatListBulletedIcon className="bg-[#dce2e8] rounded-full	p-1" />
          <span className="text-lg text-[#484848] font-bold">{searchList.title}</span>
        </div>

        <IconButton
          onClick={handleOpen}
          size="small"
        >
          <MoreVertIcon />
        </IconButton>
      </div>

      <div className="text-[#484848]">Companies: {searchList.pinned_company_ids.length}</div>

      <SearchListMenu
        listId={searchList.list_id}
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  );
};
