import API from "@aws-amplify/api-rest";
import _ from 'lodash';

export const queryElasticSearchBainIds = async ({
  company_search,
  filters,
  include_null,
  unique_company,
  lucene_query,
  currency,
}) => {
  let body = {
    company_search: _.trim(company_search),
    include_null_list: include_null,
    ...(!_.isEmpty(filters) && { filters }),
    ...(unique_company && { unique_company }),
    ...(lucene_query && { lucene_query })
  };

  return API.post("CDPAPI", `/midtier/company_search_elastic?currency=${currency}`, { body });
}

export const queryCompanySearch = async ({ company, currency }) =>
  API.post("CDPAPI", `/midtier/company_search?currency=${currency}`, {
    body: {
      company_search: company,
    },
  });
