export const readLocalStorageValue = (key) => {
  if (typeof window === 'undefined') {
    console.warn('there is no window object');

    return;
  }

  try {
    const item = window.localStorage.getItem(key);

    return item ? JSON.parse(item) : undefined;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);

    return;
  }
};

export const setLocalStorageValue = (key, value) => {
  if (typeof window === 'undefined') {
    console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
  }

  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
};
