import { useQuery } from '@tanstack/react-query';

import { getSearchLists } from '@/services/api';

export const useQuerySearchLists = () => {
  return useQuery(['search-lists'], getSearchLists, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
