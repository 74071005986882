import React from 'react';

export const IconHome = () => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19.988h2.99l.01-8.376-7.002-7.192-6.992 7.15L5 19.989h3v-7a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v7Zm-2 0h-4v-6h4v6Zm6.424-9.815-7.709-7.884c-.377-.385-1.053-.385-1.43 0l-7.71 7.885A2.075 2.075 0 0 0 3 11.612v8.376c0 1.103.847 2 1.888 2h14.223c1.041 0 1.889-.897 1.889-2v-8.376c0-.54-.21-1.063-.576-1.44Z"
      fill="#666"
    />
  </svg>
);
