import { groupBy, isArray, maxBy, sortBy } from 'lodash';
import { HISTORY_KEYS, MAX_SEARCH_HISTORY_QUERIES, QUERIES_HISTORY_EXPIRATION } from '../constants';
import {
  getDateLabelGenerator,
  getLocalDateFromTimestamp,
  getTodayLocalDate,
  getYesterdayLocalDate,
} from './dateTimeUtils';
import { HistoryQuery, QueryDetails, SearchHistory } from '@/hooks/use-save-search-query';

export const getQueryEntity = ({ searchText, isLucene, filters, companiesCount, isExport, isUniq }: QueryDetails) => {
  return {
    createTimestamp: Date.now(),
    filters,
    searchText,
    companiesCount,
    isExport,
    isLucene,
    isUniq,
  };
};

export const getLatestQuery = (queries: HistoryQuery[]): HistoryQuery =>
  maxBy(queries, 'createTimestamp') as HistoryQuery;

// it will allow duplicated exports
export const shouldSaveNewQuery = (latestQuery: HistoryQuery, newQuery: HistoryQuery): boolean =>
  !!(newQuery.searchText || newQuery?.isExport) &&
  (latestQuery?.isExport ||
    newQuery?.isExport ||
    newQuery.searchText !== latestQuery?.searchText ||
    !newQuery.isUniq !== !latestQuery?.isUniq ||
    !newQuery.isLucene !== !latestQuery.isLucene);

/**
 * Method returns N last unique non-empty items from list
 * @param list
 * @param n
 * @returns {*[]}
 */
export const getLastNSearches = (list: HistoryQuery[], n: number): HistoryQuery[] => {
  const lastItems: HistoryQuery[] = [];
  const sortedList = sortBy(list, (query) => -query.createTimestamp);

  sortedList.every((query) => {
    const alreadyInList = lastItems.some(
      (addedQuery) =>
        addedQuery.searchText === query.searchText &&
        addedQuery.isLucene === query.isLucene &&
        query.isUniq === addedQuery.isUniq,
    );

    if (!alreadyInList && query.searchText) {
      lastItems.push(query);
    }

    return lastItems.length !== n;
  });

  return lastItems;
};

/**
 * Method groups and sorts records by date
 * @param list
 * @returns {{label: string, queries: any[]}[]}
 */
export const prepareSearchHistory = (list: HistoryQuery[]) => {
  const today = getTodayLocalDate();
  const yesterday = getYesterdayLocalDate();
  const getDateLabel = getDateLabelGenerator(today, yesterday);

  const groupedMap = groupBy(list, (query) => getLocalDateFromTimestamp(query.createTimestamp));

  const groupedArray = Object.entries(groupedMap).map(([date, queries]) => ({
    orderValue: -queries[0].createTimestamp,
    label: getDateLabel(date),
    queries: sortBy(queries, (query) => -query.createTimestamp),
  }));

  const sortedHistory = sortBy(groupedArray, 'orderValue').map(({ label, queries }) => ({ label, queries }));

  return sortedHistory;
};

export const removeTooOldSearchHistoryQueries = (list: HistoryQuery[]): HistoryQuery[] => {
  const date = new Date(getTodayLocalDate());

  date.setDate(date.getDate() - QUERIES_HISTORY_EXPIRATION);
  const expirationTimestamp = date.getTime();

  return list.filter((query) => query.createTimestamp >= expirationTimestamp).slice(-MAX_SEARCH_HISTORY_QUERIES);
};

export const isHistoryVersionIsObsolete = (history: SearchHistory): boolean => isArray(history);

export const updateHistoryFormat = (history: HistoryQuery[]): SearchHistory => {
  return {
    [HISTORY_KEYS.CUSTOM_HISTORY]: history,
    [HISTORY_KEYS.SCREENERS_HISTORY]: [],
  };
};
