import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Button from '@mui/material/Button';
import _ from 'lodash';

import { SearchCard } from '@/Components/Shared/SearchCard';
import { useQuerySearchLists } from '@/hooks/queries/search-list/use-query-search-lists';
import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';

const SearchLists = () => {
  const { handleOpen } = useModal(MODALS.CREATE_NEW_LIST);
  const query = useQuerySearchLists();
  const searchLists = query.data ?? [];

  const handleOpenDialog = () => {
    handleOpen();
  };

  return (
    <div
      className="m-6"
    >
      <Button
        variant="outlined"
        className="text-[#2e3f4c] border-[#2e3f4c] py-2 px-3"
        startIcon={<FormatListBulletedIcon fontSize="small" />}
        onClick={handleOpenDialog}
      >
        Create new list
      </Button>

      <div className="flex flex-col mt-6">
        <span className="text-[#666] font-bold">Your lists</span>

        <div className="grid gap-4 grid-cols-3 mt-4">
          {query.isFetching
            ? _.range(12).map((idx) => (
                <Skeleton
                  key={idx}
                  variant="rounded"
                  className="bg-[#0000000f] rounded-2xl"
                  height={104}
                />
              ))
            : searchLists.map((searchList) => (
                <SearchCard
                  key={searchList.list_id}
                  searchList={searchList}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default SearchLists;
