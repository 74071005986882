import React, { ReactNode } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isNumber, keyBy, isNil } from 'lodash';

import { currencySign, dataNotAvailable, financialRevenueValue, numberFormatterCustom } from '../../common';
import { getConfidenceLevelData } from './utils';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQueryColumnMapper } from '@/hooks/queries/use-query-column-mapper';
import { IMidtierTable, Nullish } from '@/types';

export interface IProps extends GridRenderCellParams {
  displayName: string;
  row: IMidtierTable;
}

type TData = {
  isConfidenceLevelChecked: boolean;
  IconComponent: Nullish<ReactNode>;
  returnValue: unknown;
};

const getShouldIconShowed = ({ isConfidenceLevelChecked, IconComponent, returnValue }: TData) =>
  isConfidenceLevelChecked && !!IconComponent && !isNil(returnValue) && returnValue !== 'UNKNOWN';

const getCellColorStyle = (returnValue: unknown) => {
  if (!isNumber(returnValue)) {
    return {};
  }

  const backgroundColor = returnValue > 0.5 ? '#DEF3E5' : returnValue < -0.5 ? '#FAC8CB' : '#DDDDDD';
  const color = returnValue > 0.5 ? '#4BBE70' : returnValue < -0.5 ? '#CC2936' : '#8a8a8a';

  return {
    backgroundColor,
    color,
  };
};

export const CommonCell = ({ displayName, field, row }: IProps) => {
  const columnMapperQuery = useQueryColumnMapper();
  const isTableLoading = useIsFetching({ queryKey: ['table-midtier'] }) > 0;
  const isSearchListLoading = useIsFetching({ queryKey: ['search-list'] }) > 0;
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const isConfidenceLevelChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);
  const columnMapper = keyBy(columnMapperQuery.data ?? [], 'Display Name');
  const isLoading = isTableLoading || isSearchListLoading;

  if (isLoading) {
    return (
      <Skeleton
        data-testid="table-skeleton-loader"
        className="w-full h-full bg-[#0000000f]"
      />
    );
  }

  const returnValue = row[field];
  const resultConfidence = row[`${field}confidence`];
  const resultMetadata = row[`${field}metadata`];
  const filterType = columnMapper?.[displayName]?.['NUMERIC_TYPE'] ?? null;
  const confidenceLevelData = getConfidenceLevelData(resultConfidence as number);
  const IconComponent = confidenceLevelData?.Icon;
  const cellColorStyle = getCellColorStyle(returnValue);
  const hasUnknownValue = isNil(returnValue) || returnValue === 'UNKNOWN';
  const hideCellTooltip = (!resultMetadata && !row[field]) || hasUnknownValue;

  const shouldIconShowed = getShouldIconShowed({
    isConfidenceLevelChecked,
    IconComponent,
    returnValue,
  });

  const Icon = shouldIconShowed && (
    // @ts-ignore
    <IconComponent
      color="error"
      fontSize="small"
      className="absolute"
      style={{ ...confidenceLevelData?.props?.style, width: 15, left: 8, top: 7 }}
    />
  );

  const toolTipWindow = hideCellTooltip ? null : (
    <div>
      {!!resultConfidence && (
        <>
          <div>
            <b>{`Confidence score: (${resultConfidence}) ${confidenceLevelData?.text}`} </b>
            {!!IconComponent && (
              <IconComponent
                fontSize="small"
                style={{ width: 15 }}
              />
            )}
            <br />
            <b>{confidenceLevelData?.description}</b>
          </div>
          <br />
        </>
      )}
      <b className="font-black text-xs">{displayName}: </b> {row[field]}
      <br />
      <b className="font-black text-xs">Origin: </b>
      {resultMetadata}
    </div>
  );

  if (filterType === '%') {
    return (
      <Tooltip title={toolTipWindow}>
        <div
          style={cellColorStyle}
          className="percentageCellStyle overflow-hidden"
        >
          {Icon}
          <div className="pl-4 flex items-center">
            <span className="w-full inline-block truncate">
              {returnValue === 0 ? returnValue : returnValue === null ? '' : `${Math.round(returnValue as number)}%`}
            </span>
          </div>
        </div>
      </Tooltip>
    );
  } else if ((filterType === 'EUR' || filterType === 'USD') && field === 'self_financials_revenue___') {
    const value = !isNil(returnValue)
      ? financialRevenueValue(
          row['self_financials_revenue___'],
          row['self_financials_revenue___min'],
          row['self_financials_revenue___max'],
          currency,
        )
      : dataNotAvailable(!hideCellTooltip);

    return (
      <Tooltip title={toolTipWindow}>
        <div className="w-full h-full">
          {Icon}
          <div className="pl-4 w-full h-full">
            <span className="w-full inline-block truncate">{value}</span>
          </div>
        </div>
      </Tooltip>
    );
  } else if (filterType === 'EUR' || filterType === 'USD') {
    const value = !isNil(returnValue)
      ? currencySign(currency) + numberFormatterCustom(returnValue)
      : dataNotAvailable(!hideCellTooltip);

    return (
      <Tooltip title={toolTipWindow}>
        <div className="w-full h-full">
          {Icon}
          <div className="pl-4 w-full h-full">
            <span className="w-full inline-block truncate">{value}</span>
          </div>
        </div>
      </Tooltip>
    );
  } else {
    let result = null;

    if (hasUnknownValue) {
      result = <span>{dataNotAvailable(!hideCellTooltip)}</span>;
    } else if (typeof returnValue === 'number') {
      const roundedResult = Math.round((returnValue + Number.EPSILON) * 100) / 100;

      result = (
        <span className="w-full inline-block truncate">
          {filterType === '#' ? roundedResult.toLocaleString('en-US') : roundedResult}
        </span>
      );
    } else {
      result = <span className="w-full inline-block truncate">{returnValue}</span>;
    }

    return (
      <Tooltip
        title={toolTipWindow}
        placement="bottom-start"
      >
        <div className="w-full h-full flex items-center">
          {Icon}
          <div className="pl-4 w-full h-full">{result}</div>
        </div>
      </Tooltip>
    );
  }
};
