import { rest } from 'msw';

export const availableNumericalFilterMock = {
  backendName: 'self_firmo_employeeCount___',
  displayName: 'Employee Count',
  __type__: 'numerical',
  unit: 'count',
};

export const availableCategoricalFilterMock = {
  backendName: 'self_firm_statusL2___',
  displayName: 'Status Detail',
  __type__: 'categorical',
  unit: null,
};
export const availableTreeFilterMock = {
  backendName: 'parent_sector_sector___',
  displayName: 'Parent Sector',
  __type__: 'tree',
  unit: null,
};

export const availableCategoryFiltersMock = [
  availableNumericalFilterMock,
  availableCategoricalFilterMock,
  availableTreeFilterMock,
];

export const availableFiltersMock = [
  {
    displayHeader: 'Firmographics',
    items: [availableNumericalFilterMock, availableCategoricalFilterMock],
  },
  {
    displayHeader: 'Parent',
    items: [availableTreeFilterMock],
  },
];

export const numericalFilterDataMock = {
  min: 0,
  min_hint: '0',
  max: 2300000,
  max_hint: '4M',
};

export const categoricalFilterDataMock = [
  {
    value: 'Active',
    active: 16784509,
    total: 16784509,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Active Parent',
    active: 348022,
    total: 348022,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Active Subsidiary',
    active: 1393231,
    total: 1393231,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Not a company status',
    active: 263396,
    total: 263396,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Not operating',
    active: 1423935,
    total: 1423935,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'UNKNOWN',
    active: 24527905,
    total: 24527905,
    _backend: 'self_firm_statusL2___',
  },
];

export const treeFilterDataMock = [
  {
    value: 'Business Services',
    active: 139783,
    total: 139783,
    _backend: 'parent_sector_sector___',
    children: [
      {
        value: 'BPO',
        active: 361,
        total: 361,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'BPO - Unspecified',
            active: 253,
            total: 253,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'BPO - Unspecified',
                active: 253,
                total: 253,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
          {
            value: 'Customer Management (Callcenters)',
            active: 1,
            total: 1,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Customer Management (Callcenters)',
                active: 1,
                total: 1,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
      {
        value: 'Business Services - Unspecified',
        active: 19944,
        total: 19944,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'Business Services - Unspecified',
            active: 19944,
            total: 19944,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Business Services - Unspecified',
                active: 19942,
                total: 19942,
                _backend: 'parent_sector_subIndustry___',
              },
              {
                value: 'UNKNOWN',
                active: 2,
                total: 2,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 'Consumer',
    active: 200109,
    total: 200109,
    _backend: 'parent_sector_sector___',
    children: [
      {
        value: 'Consumer - Unspecified',
        active: 28,
        total: 28,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'Consumer - Unspecified',
            active: 28,
            total: 28,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Consumer - Unspecified',
                active: 28,
                total: 28,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
      {
        value: 'Consumer Durables & Apparel',
        active: 35174,
        total: 35174,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'Household Durables',
            active: 8875,
            total: 8875,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Home Furnishings',
                active: 3718,
                total: 3718,
                _backend: 'parent_sector_subIndustry___',
              },
              {
                value: 'Household Appliances',
                active: 3337,
                total: 3337,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
          {
            value: 'Leisure Products',
            active: 4648,
            total: 4648,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Leisure Products - Other',
                active: 2,
                total: 2,
                _backend: 'parent_sector_subIndustry___',
              },
              {
                value: 'Leisure Products - Unspecified',
                active: 4643,
                total: 4643,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const filtersController = [
  rest.post(`${process.env.REACT_APP_API_URL}/filters_tree`, (_, res, ctx) =>
    res(
      ctx.json({
        self_firmo_employeeCount___: numericalFilterDataMock,
        self_firm_statusL2___: categoricalFilterDataMock,
        parent_sector_sector___: treeFilterDataMock,
      }),
    ),
  ),
  rest.get(`${process.env.REACT_APP_API_URL}/filters/available`, (_, res, ctx) =>
    res(
      ctx.json({
        data: availableFiltersMock,
      }),
    ),
  ),
];
