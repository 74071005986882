import React, { useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip, Link } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { normalizeFilters } from '@/Utils/filters';

import { useQueryColumnMapper } from '@/hooks/queries/use-query-column-mapper';
import { getCurrentFiltersToDisplay } from '../../../../Utils/filters';

export const ExportRecord = ({ query, onSelect }) => {
  const [expanded, setExpanded] = useState(false);
  const columnMapperQuery = useQueryColumnMapper();
  const columnMapper = columnMapperQuery.data ?? [];
  const { searchText, companiesCount, filters, isLucene } = query;

  const normalizedFilters = { ...filters, otherFilters: normalizeFilters(filters.otherFilters) };

  const filtersToDisplay = useMemo(
    () => getCurrentFiltersToDisplay(normalizeFilters(normalizedFilters), columnMapper),
    [columnMapper, normalizedFilters],
  );

  const handleExpandClick = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    onSelect?.();
  };

  return (
    <Accordion
      className="shadow-[0_4px_4px_rgba(0, 0, 0, 0.25)]"
      expanded={expanded}
      onChange={handleExpandClick}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="flex grow">
          <Chip
            className="rounded"
            color="success"
            variant="outlined"
            label="Exported"
          />
          <Typography className="ml-4 self-center">Companies - {companiesCount ?? 0}</Typography>
        </Box>
        <Link
          className="mr-4 w-22"
          component="button"
          onClick={handleSearch}
        >
          Apply criteria
        </Link>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="mb-4">{isLucene ? searchText : `"${searchText}"`}</Typography>
        {filtersToDisplay?.map(({ label, value }) => (
          <Typography key={label}>{`${label}: ${value}`}</Typography>
        ))}
        <Typography></Typography>
      </AccordionDetails>
    </Accordion>
  );
};
