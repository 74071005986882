import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isAuth: false,
  email: null as string | null,
};

export const {
  reducer, actions,
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});

export type TState = typeof initialState;