import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

type TNumericalFilter = {
  min?: number | null;
  max?: number | null;
  min_ui_data?: number | null;
  max_ui_data?: number | null;
  excludeNullValues: boolean;
};

type TFilter = string[] | TNumericalFilter;

export interface Filters {
  treeFilters: Record<string, string>;
  otherFilters: Record<string, TFilter>;
  includedNullList: string[];
}

const initialState: Filters = {
  treeFilters: {},
  otherFilters: {},
  includedNullList: [],
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (state, { payload: { id, data } }) => {
      state.otherFilters[id] = data;
    },
    setFilterTree: (state, { payload: { id, data } }) => {
      state.treeFilters[id] = data;
    },
    setFilters: (__, { payload }) => {
      return payload;
    },
    setOtherFilters: (state, { payload }) => {
      return { treeFilters: state.treeFilters, includedNullList: [], otherFilters: payload };
    },
    setIdToNullList: (state, { payload }) => {
      state.includedNullList.includes(payload)
        ? (state.includedNullList = _.without(state.includedNullList, payload))
        : state.includedNullList.push(payload);
    },
    addIdToNullList: (state, { payload }) => {
      state.includedNullList = _.uniq([...state.includedNullList, payload]);
    },
    removeIdFromNullList: (state, { payload }) => {
      state.includedNullList = _.without(state.includedNullList, payload);
    },
    setIdsToNullList: (state, { payload }) => {
      state.includedNullList = _.uniq([...state.includedNullList, ...payload]);
    },
    removeFilter: (state, { payload }) => {
      _.unset(state.otherFilters, payload);
    },
    removeFilterTree: (state, { payload }) => {
      _.unset(state.treeFilters, payload);
    },
    resetFilters: () => {
      return initialState;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

export type TState = typeof initialState;
