import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { useQuerySearchLists } from './use-query-search-lists';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { updateSearchList } from '@/services/api';
import { IUpdateSearchList } from '@/types';
import { pluck } from '@/Utils';

export const useMutationUpdateSearchList = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const querySearchLists = useQuerySearchLists();
  const selectedCompanies = useShallowSelector((state) => state.customScreen.selectedCompanies);

  return useMutation({
    mutationKey: ['update-search-list'],
    mutationFn: (id: string) => {
      const currentListIds = querySearchLists.data?.find(item => item.list_id === id)?.pinned_company_ids ?? [];
      const result = _.union(currentListIds, pluck('id', selectedCompanies));

      const body = {
        pinned_company_ids: result,
      } as IUpdateSearchList;

      return updateSearchList({ id, body });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['search-list', response.list_id] });
      queryClient.invalidateQueries({ queryKey: ['search-lists'] });

      enqueueSnackbar(
        <NotificationMessage
          title={`The ${response.title} list has been successfully updated`}
          href={{
            to: `/search-list/${response.list_id}`,
            name: 'Go to the list',
          }}
        />,
        { variant: 'success' },
      );
    },
  });
};
