import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { getLocalTimeFromTimestamp } from '../../../../Utils/dateTimeUtils';
import { ExportRecord } from '../ExportRecord';
import { SearchRecord } from '../SearchRecord';

export const QueryRecord = ({ query, onSearch }) => {
  const { createTimestamp, isExport } = query;
  const time = getLocalTimeFromTimestamp(createTimestamp);

  const handleSearch = () => {
    onSearch?.(query);
  }

  return (
    <Box className="flex">
      <Typography className="flex-none w-20">{time}</Typography>
      <Box className="flex-auto">
        {isExport
          ? <ExportRecord query={query} onSelect={handleSearch} />
          : <SearchRecord query={query} onSelect={handleSearch} />}
      </Box>
    </Box>
  );
};
