/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import classnames from 'classnames';

import { useSearchCompanies } from '../../hooks/use-search-companies';
import ErrorDialog from '../Shared/ErrorDialog';
import { queryCompanySearch } from '../../Api/search-api.service';
import { IconFilterSettings } from '../Icons/IconFilterSettings';
import { IconSearch } from '../Icons/IconSearch';
import { actions, QueryType } from '../../slices/search';
import { useShallowSelector } from '../../hooks/use-shallow-selector';
import { useModal } from '../../hooks/use-modal';
import { MODALS, DEFAULT_ERROR_MESSAGE, SEARCH_EVENTS } from '../../constants';
import { clearSearchSubject } from '../../Utils/subjects';

import '../../App.css';
import { Loader } from '../Shared/Loader/Loader';
import { SavedQueries } from './SavedQueries';
import HistoryDropdown from './HistoryDropdown';
import SearchTypeDropdown from './SearchTypeDropdown';
import { useScreener } from '@/hooks/use-screen';
import { FiltersDrawer } from '@/Components/Shared/FiltersDrawer';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

export const StyledSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    padding: '9px',
    position: 'absolute',
    color: '#fff',
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgb(204, 0, 0)',
        opacity: 1,
        height: '19px',
        marginTop: '-2px',
        borderRadius: '10px',
      },
    },
    '& .MuiSwitch-input': {
      width: '100%',
      marginLeft: '50px',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  '& .MuiSwitch-track': {
    height: '19px',
    marginTop: '-2px',
    borderRadius: '10px',
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '0',
    color: 'black',
    borderColor: '#DDD',
    minHeight: '36px',
  },
  '&.MuiAutocomplete-listbox.MuiAutocomplete-option': {
    fontSize: '12px !important',
  },
}));

const StyledTextField = styled(TextField)(({ error }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: error ? '#D63333' : 'black',
      borderWidth: '1px',
    },

    '&.Mui-focused fieldset': {
      borderColor: error ? '#D63333' : '#0484E7',
      borderWidth: '2px',
    },
  },
}));

function Search() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const searchCompanies = useSearchCompanies();
  const { resetAll } = useScreener();
  const inputRef = useRef(null);

  const {
    isLoading: isBainIdsLoading,
    isError: isBainIdsError,
    errorStatus: bainIdsErrorStatus,
    errorMessage: bainIdsErrorMessage,
  } = useShallowSelector((state) => state.search.bainIds);
  const searchText = useShallowSelector((state) => state.search.searchText);
  const hasLuceneGrammarError = useShallowSelector((state) => state.search.hasLuceneGrammarError);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const { handleOpen: openAdvancedSearchDialog } = useModal(MODALS.ADVANCED_SEARCH);
  const [typeAheadList, setTypeAheadList] = useState([]);

  const [type, setType] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isHistoryOpened, setIsHistoryOpened] = useState(false);

  const [isCompanySearchLoading, setCompanySearchLoading] = useState(false);

  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isMatchQuery = queryType === QueryType.MATCH;
  const isSmartQuery = queryType === QueryType.SMART;

  const handleClearSearch = () => {
    dispatch(
      actions.setIsError({
        value: false,
        status: null,
        message: null,
      }),
    );
    setTypeAheadList([]);
  };

  useEffect(() => {
    dispatch(
      actions.setIsError({
        value: false,
        status: null,
        errorMessage: null,
      }),
    );

    const subscription = clearSearchSubject.subscribe(() => {
      handleClearSearch();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    if (isBainIdsError && bainIdsErrorStatus === 400) {
      const isQueryProblem = bainIdsErrorMessage !== DEFAULT_ERROR_MESSAGE;

      enqueueSnackbar(
        <NotificationMessage
          title={isQueryProblem ? 'Your query is not valid' : DEFAULT_ERROR_MESSAGE}
          description={isQueryProblem ? bainIdsErrorMessage : undefined}
        />,
        { variant: 'error' },
      );
      dispatch(actions.setSearchQuery(''));
    }
  }, [isBainIdsError]);

  useEffect(() => {
    if (queryType === QueryType.SMART) return;
    let typingTimer = setTimeout(() => {
      if (type === 'change') {
        updateTypeAhead(searchText);
      }
    }, 300);

    if (isBainIdsError) {
      dispatch(
        actions.setIsError({
          value: false,
          status: null,
          errorMessage: null,
        }),
      );
    }

    return () => clearTimeout(typingTimer);
  }, [searchText, queryType]);

  const updateTypeAhead = async (value) => {
    let typeAheadValues = [];

    if (value.length >= 3 && !isKeywordQuery) {
      let response;

      setCompanySearchLoading(true);

      try {
        response = await queryCompanySearch({ company: value, currency });
      } finally {
        setCompanySearchLoading(false);
      }

      if (response) {
        Object.values(response).forEach((item) => {
          if (item) {
            typeAheadValues.push(item);
          }
        });
      }

      if (typeAheadValues == undefined || !value) {
        setTypeAheadList([]);
      } else if (typeAheadValues.length < 1) {
        typeAheadValues.push(`"${value}"`);
        setTypeAheadList(typeAheadValues);
      } else {
        typeAheadValues[0].unshift(`"${value}"`);
        setTypeAheadList(typeAheadValues[0]);
      } // wipe Id's and start clean
    }
  };

  const handleInputChange = (event, value) => {
    if (event && event?.code !== 'Enter') {
      dispatch(actions.setSearchText(value));
      setType(event?.type);
    }
  };

  const handleOnChange = (event, value) => {
    if (event.type === 'click') {
      const idx = +event.target.dataset?.optionIndex;
      const isUniq = idx !== 0;

      searchCompanies({
        searchText: isUniq ? value : searchText,
        isUniq,
      });

      setTypeAheadList([]);
      setIsFocused(false);
    }
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }

    if (event.keyCode === 13 && event.target.value.length > 0) {
      search(searchText);
    }
  };

  const handleHistoryToggle = (isOpened) => {
    setIsHistoryOpened(isOpened);
  };

  const search = async (searchText) => {
    searchCompanies({ searchText });
    setTypeAheadList([]);
    setIsFocused(false);
  };

  const handleSearchSettings = (event) => {
    event.stopPropagation();

    openAdvancedSearchDialog();
  };

  const handleHistorySearch = (query) => {
    searchCompanies({ ...query, eventName: SEARCH_EVENTS.HISTORY }, { useStateFilters: true });
  };

  const isSearchIconLoading = isBainIdsLoading || isCompanySearchLoading;

  const getPlaceholderText = () => {
    if (isKeywordQuery) return 'Search for companies using keywords...';
    else if (isMatchQuery) return 'Search for companies using a name, URL or description...';
    else if (isSmartQuery) return 'Give me a list of companies that ... (type here)';
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Box
          className="flex width-[790px]"
          ml={1}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledAutocomplete
              open={isFocused && !isKeywordQuery && !isHistoryOpened}
              id="company-name"
              freeSolo={true}
              inputValue={searchText}
              className="search-bar w-[790px]"
              options={typeAheadList}
              onInputChange={handleInputChange}
              onChange={handleOnChange}
              onKeyDown={handleEnter}
              onFocus={() => setIsFocused(!isHistoryOpened)}
              onBlur={() => setIsFocused(false)}
              autoHighlight
              renderOption={(props, option) => {
                const isFirstOption = props.id === 'company-name-option-0';
                const { length } = searchText;
                const result = [option.substring(0, length), option.substring(length, Infinity)];

                return (
                  <>
                    <li
                      {...props}
                      className={classnames('text-sm font-normal cursor-pointer', {
                        'text-[#999999] italic py-2.5 mx-2.5 border-b-[#ddd] border-b': isFirstOption,
                        'p-2.5 autocomplete-option': !isFirstOption,
                      })}
                    >
                      <span>{result[0]}</span>
                      <b>{result[1]}</b>
                    </li>
                    {isFirstOption && (
                      <div
                        style={{ fontSize: 6 }}
                        className="mx-2.5 mt-2.5 uppercase text-[#999] text-xs"
                      >
                        Companies
                      </div>
                    )}
                  </>
                );
              }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  ref={inputRef}
                  error={(isBainIdsError && bainIdsErrorStatus === 400) || hasLuceneGrammarError}
                  id="name-query"
                  data-testid="search-field"
                  placeholder={getPlaceholderText()}
                  variant="outlined"
                  multiline
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 12 },
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      notchedOutline: 'border-bluegray-900',
                    },
                    startAdornment: (
                      <>
                        <InputAdornment
                          position="start"
                          className="ml-2 mr-1 min-h-[36px]"
                        >
                          <SearchTypeDropdown ref={inputRef} />
                        </InputAdornment>
                        <Divider
                          orientation="vertical"
                          flexItem
                          className="bg-bluegray-900"
                        />
                        {isSearchIconLoading ? (
                          <div className="mr-1 ml-2.5">
                            <Loader />
                          </div>
                        ) : (
                          <Tooltip title="Search">
                            <IconButton
                              onClick={() => search(searchText)}
                              className="ml-1 mb-[1px]"
                              size="small"
                            >
                              <IconSearch />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="m-0 mr-2"
                      >
                        {isFocused && searchText && (
                          <IconButton
                            onClick={handleClearSearch}
                            size="small"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                        {isKeywordQuery && (
                          <IconButton
                            onClick={handleSearchSettings}
                            size="small"
                            data-testid="advanced-search"
                          >
                            <IconFilterSettings />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              classes={{
                popper: 'mt-1 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]',
              }}
            />
          </Box>
          <Box className="flex">
            <Box>
              <HistoryDropdown
                onDropdownToggle={handleHistoryToggle}
                onQuerySelect={handleHistorySearch}
              />
            </Box>
            <FiltersDrawer />
            <Button
              className="px-2 py-2 mr-2 border-bluegray-900 text-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-xs h-[36px]"
              onClick={resetAll}
              id="custom-reset-button"
            >
              Clear
            </Button>
          </Box>
        </Box>
        <ErrorDialog throwError={isBainIdsError && bainIdsErrorStatus !== 400} />
      </div>
      <div className="flex flex-wrap max-w-[1100px]">{(isKeywordQuery || isSmartQuery) && <SavedQueries />}</div>
    </div>
  );
}

export default Search;
