import { createTheme } from '@mui/material/styles'; 

export const theme = createTheme({
    palette: {
      type: 'light',
      secondary: {
        main: '#36A6FC',
      },
      background: {
        default: '#2E2E2E',
        paper: '#1E1E1E'
      },
      success: {
        main: '#21A663',
      },
      warning: {
        main: '#F59B00',
      },
      error: {
        main: '#D63333',
      },
    },
    radii:{
      none:"0px",
      small:"4px",
      medium:"10px",
      large:"100px",
    },
    typography: {
      fontFamily: '"Graphik", sans-serif',
      spacing: 8,
      fontSize: 14,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightBold: 600,
      uber: {
        fontSize: '111px',
        lineHeight: 1.08,
      },
      hero: {
        fontSize: '77px',
        lineHeight: 1.11,
      },
      h1: {
        fontSize: '34px',
        lineHeight: 1.2,
      },
      h2: {
        fontSize: '28px',
        lineHeight: 1.6,
      },
      h3: {
        fontSize: '24px',
        lineHeight: 1.6,
        fontWeight: 600
      },
      h4: {
        fontSize: '21px',
        lineHeight: 1.6,
      },
      h5: {
        fontSize: '17px',
        lineHeight: 1.268,
      },
      h6: {
        fontSize: "16px",
      },
      lead: {
        fontSize: '24px',
        lineHeight: 1.29,
      },
      body: {
        fontSize: '14px',
        lineHeight: 1.6,
      },
      body1: {
        fontSize: '14px',
        lineHeight: '1.6'
      },
      small: {
        fontSize: '12px',
        lineHeight: 1.6,
        fontFamily: '"Graphik", sans-serif'
      },
      caption: {
        fontSize: '12px',
        lineHeight: 1.22,
      },
      tiny: {
        fontSize: '10px',
        lineHeight: 1.38,
      },
      button: {
        textTransform: 'none',
        fontWeight: 600
      }
    },
    breakpoints:[
      "40em",
      "52em",
      "64em",
      ],
    space:[
      0,
      8,
      16,
      24,
      32,
      40,
      48,
      56,
      64,
      72,
      80,
      88,
      96
      ],
    letterSpacings:{
        caption:"1px"
      },
    shadows:{
      0: "0 1px 3px hsla(220, 3%, 19%, 0.14)",
      1:"0 1px 3px hsla(220, 3%, 19%, 0.14)",
      8:"0 1px 3px hsla(220, 3%, 19%, 0.14)",
      16:"0 1px 3px hsla(220, 3%, 19%, 0.14)",
      20:"0 1px 3px hsla(220, 3%, 19%, 0.14)",
      24:"0 1px 3px hsla(220, 3%, 19%, 0.14)",
      40:"0 1px 4px hsla(220, 3%, 19%, 0.14)",
      60:"0 2px 8px hsla(220, 3%, 19%, 0.14)",
      80:"0 4px 16px hsla(220, 3%, 19%, 0.14)",
      100:"0 7px 24px hsla(220, 3%, 19%, 0.14)"
    },
    sizes:{
      micro:"16px",
      tiny:"24px",
      smaller:"32px",
      small:"48px",
      medium:"64px",
      large:"80px",
      dialogWidths:{
        sm: 440,
        md: 540,
        lg: 660,
        xl: 800
      }
    }
})
