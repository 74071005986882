import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

interface IProps {
  title: string;
  description?: string;
  href?: { to: string; name: string };
}

export const NotificationMessage = ({ title, description, href }: IProps) => {
  return (
    <div className="flex flex-col px-2">
      {title ? (
        <Typography
          variant="body1"
          className="font-bold"
        >
          {title}
        </Typography>
      ) : null}

      {description ? (
        <Typography
          variant="body1"
          gutterBottom
          style={{ wordWrap: 'break-word' }}
        >
          {description}
        </Typography>
      ) : null}

      {href && (
        <Link to={href.to}>
          <span className="border-b text-xs">{href.name}</span>
        </Link>
      )}
    </div>
  );
};
