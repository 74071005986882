import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { createSearchList } from '@/services/api';
import { MODALS } from '@/constants';
import { QueryType } from '@/slices/search';

type TMutationPayload = {
  title: string;
  description: string;
};

export const useMutationCreateSearchList = (resetCallback: () => void) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useModal(MODALS.CREATE_NEW_LIST);
  const company_search = useShallowSelector((state) => state.search.searchQuery);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const filters = useShallowSelector((state) => state.filters);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const pinned_company_ids = useShallowSelector((state) =>
    state.customScreen.selectedCompanies.map(({ id }) => `${id}`),
  );
  const lucene_query = queryType === QueryType.KEYWORD;

  const body = {
    company_search,
    lucene_query,
    filters,
    pinned_company_ids,
    currency,
    excluded_company_ids: [],
    sort_key: null,
    sort_order: null,
  };

  return useMutation({
    mutationKey: ['create-search-list'],
    mutationFn: (payload: TMutationPayload) => createSearchList({ ...payload, ...body }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['search-lists'] });

      resetCallback();
      handleClose();

      enqueueSnackbar(
        <NotificationMessage
          title={`${response.pinned_company_ids.length} companies added to ${response.title}`}
          href={{
            to: `/search-list/${response.list_id}`,
            name: 'Go to the list',
          }}
        />,
        { variant: 'success' },
      );
    },
  });
};
