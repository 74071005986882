import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import classnames from 'classnames';

import { useModal } from '../../../../hooks/use-modal';
import { useShallowSelector } from '../../../../hooks/use-shallow-selector';
import { getUserEmail } from '../../../../Api/user-api.service';
import { downloadCompanyOverview } from '../../../../Api/company-overview-api.service';
import { MODALS } from '../../../../constants';
import { NotificationMessage } from '../../../Shared/Notifications/NotificationMessage';

export const CompanyOverviewDialog = () => {
  const { isOpen, handleClose } = useModal(MODALS.COMPANY_OVERVIEW);
  const selectedCompanies = useShallowSelector((state) => state.customScreen.selectedCompanies);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const caseCodeFormData = useShallowSelector((state) => state.caseCode.formData);
  const [mainCompanyId, setMainCompanyId] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setMainCompanyId(selectedCompanies[0]?.id);
  }, [selectedCompanies]);

  const handleSetMainCompanyId = (_, id) => {
    setMainCompanyId(id);
  };

  const download = async () => {
    setDownloading(true);

    const email = await getUserEmail();
    const competitor_ids = selectedCompanies.map((company) => company['id']);

    try {
      await downloadCompanyOverview(mainCompanyId, competitor_ids, currency, email, caseCodeFormData);
      enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
            Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );
    } catch (err) {
      enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      });
    }

    setDownloading(false);
    handleClose();
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        classes={{ paper: 'w-1/3' }}
      >
        <DialogTitle classes={{ root: 'font-bold text-base' }}>Create company overview</DialogTitle>

        <CloseIcon
          fontSize="small"
          className="absolute right-3 top-4 cursor-pointer"
          onClick={handleCloseDialog}
        />

        <DialogContent className="pt-0">
          <DialogContentText className="flex flex-col text-sm">
            <div className="pt-2">
              We`ll provide a slide deck with an overview of the companies you`ve selected, including financial data. An
              excel with data used will be provided for analysis.
            </div>
            <div className="pt-2">
              If multiple companies have been selected, financial metrics will be compared against selected target.
            </div>

            <FormControl className="mt-4">
              <FormLabel
                className="mb-2 font-bold text-base"
                focused={false}
              >
                Select target company
              </FormLabel>
              <RadioGroup
                name="mainCompany"
                value={mainCompanyId}
                onChange={handleSetMainCompanyId}
              >
                {selectedCompanies.map(({ id, companyName }) => (
                  <FormControlLabel
                    key={id}
                    value={id}
                    control={<Radio color="secondary" />}
                    label={companyName}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <div className="flex justify-end">
              <Button
                className={classnames('bg-cred-50 text-white px-4 py-2.5', {
                  'opacity-50': downloading,
                })}
                onClick={download}
                disabled={downloading}
              >
                Download
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
