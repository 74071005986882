import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

interface IProps {
  headerName: string;
}

export const CompanyHeader = ({ headerName }: IProps) => (
  <>
    <span
      className="MuiDataGrid-columnHeaderTitle"
      style={{ marginLeft: '10px', marginTop: '-2px' }}
    >
      {headerName}
    </span>
    <Tooltip
      // eslint-disable-next-line react/no-unescaped-entities
      title={<b>Click on company's name for more details</b>}
      arrow
    >
      <span style={{ marginLeft: '4px' }}>
        <InfoOutlined
          fontSize="small"
          id="info-outlined"
        />
      </span>
    </Tooltip>
  </>
);
