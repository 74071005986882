import { configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { rootReducer } from '@/slices';
import { IS_DEV } from '@/constants';

const storeConfig = {
  reducer: rootReducer,
  devTools: IS_DEV,
};

export const store = configureStore(storeConfig);

export const setupStore = (preloadedState?: PreloadedState<TRootState>) => {
  return configureStore({
    ...storeConfig,
    preloadedState,
  });
};

export type TRootState = ReturnType<typeof store.getState>;
export type TAppStore = ReturnType<typeof setupStore>;
export type TAppDispatch = typeof store.dispatch;
