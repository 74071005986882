import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';

export const NotificationCloseAction = (snackbarId) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Close
      id="close-icon"
      onClick={() => closeSnackbar(snackbarId)}
      sx={{ cursor: 'pointer' }}
    />
  );
};
