import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import { debounce, Dictionary, groupBy } from 'lodash';

import { DEFAULT_VISIBLE_COLUMNS, SECTIONS_ORDER } from '@/constants';
import { groupAndSortList } from '@/Utils';
import { useQueryColumnMapper } from '@/hooks/queries/use-query-column-mapper';
import { ICollumnMapper } from '@/types';

interface BasicColumn {
  section: string;
  headerName?: string;
  hide?: boolean;
  field?: string;
}

const FIELDS_TO_BE_OMITED = ['id'];

type SectionData = [string, BasicColumn[]];

export const EXTENDED_DEFAULT_COLUMNS = ['__check__', ...DEFAULT_VISIBLE_COLUMNS];

const StyledTextField = styled(TextField)(() => ({
  '& .MuiFilledInput-root:hover:before': {
    borderBottomColor: 'black',
  },
}));

const ColumnsVisibilityPanel = () => {
  const columnMapperQuery = useQueryColumnMapper();
  const columnMapper = useMemo(() => columnMapperQuery.data ?? [], [columnMapperQuery.data]);
  const [searchValue, setSearchValue] = useState<string>('');

  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();

  const filterColumns = useCallback(
    (allCols: SectionData[], searchVal: string) =>
      !searchVal
        ? allCols
        : allCols.reduce((res, [section, cols]) => {
            const filteredCols = cols.filter((x) => x.headerName?.toLowerCase().includes(searchVal));

            if (filteredCols.length) {
              res.push([section, filteredCols]);
            }

            return res;
          }, [] as SectionData[]),
    [],
  );

  const getSectionByField = useCallback(
    (field: string, groupedColumnMapper: Dictionary<ICollumnMapper[]>) =>
      EXTENDED_DEFAULT_COLUMNS.includes(field)
        ? 'Default'
        : groupedColumnMapper[field]?.[0]?.['Display Header'] || 'Others',
    [],
  );

  const groupedColumnMapper = useMemo(() => groupBy(columnMapper, 'Backend Name'), [columnMapper]);

  const handleToggleColumn = (field: string, checked: boolean) => {
    apiRef.current.setColumnVisibility(field, checked);
  };

  const handleHideAllColumns = () => {
    columns.forEach(({ field }) => {
      apiRef.current.setColumnVisibility(field, false);
    });
  };

  const handleShowAllColumns = () => {
    columns.forEach(({ field }) => {
      apiRef.current.setColumnVisibility(field, true);
    });
  };

  const handleSearch = debounce((searchVal: string) => {
    setSearchValue(searchVal.trim().toLowerCase());
  }, 300);

  const groupedColumns = useMemo(() => {
    const columnsWithSections: BasicColumn[] = columns.map(({ field, headerName, hide }) => ({
      section: getSectionByField(field, groupedColumnMapper),
      headerName,
      field,
      hide,
    }));

    return groupAndSortList(columnsWithSections, SECTIONS_ORDER, 'section');
  }, [columns, getSectionByField, groupedColumnMapper]);

  const filteredColumns = useMemo(
    () => (searchValue ? filterColumns(groupedColumns, searchValue) : groupedColumns),
    [filterColumns, groupedColumns, searchValue],
  );

  return (
    <Box className="flex flex-col max-w-5xl relative my-2 mx-2.5">
      <StyledTextField
        onChange={(e: React.ChangeEvent<{ value: string }>) => handleSearch(e.target.value)}
        label="Find column"
        type="search"
        variant="filled"
        size="small"
        classes={{ root: 'mb-[10px]' }}
        InputProps={{
          className: 'text-[#666666] bg-white',
        }}
      />
      <Box className="overflow-y-auto">
        {filteredColumns.map(([section, cols]) => {
          const id = `${section}-content`;

          return (
            <Accordion
              key={section}
              className="shadow-none"
              classes={{ root: 'before:h-0', expanded: 'm-0' }}
            >
              <AccordionSummary
                className="w-min min-h-[22px] border-0 mb-[4px] pl-[9px]"
                classes={{ content: 'm-0' }}
                expandIcon={<ExpandMoreIcon className="w-6 h-4" />}
                aria-controls={id}
                id={id}
              >
                <Typography className="uppercase text-[12px] font-medium text-[#484848] whitespace-nowrap">
                  {section}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className="flex flex-col">
                  {cols?.map(({ field, hide, headerName }) =>
                    field && FIELDS_TO_BE_OMITED.includes(field) ? null : (
                      <FormControlLabel
                        key={id + headerName}
                        control={
                          <Switch
                            checked={!hide}
                            onChange={(e) => handleToggleColumn(field as string, e.target.checked)}
                            classes={{
                              track: hide ? 'h-[11px]' : 'bg-[#cc0000] opacity-100 h-[11px]',
                              thumb: 'bg-white w-[17px] h-[17px]',
                              root: 'w-[53px]',
                            }}
                          />
                        }
                        label={headerName}
                        className="h-7"
                        classes={{ label: 'text-[14px] text-[#484848]' }}
                      />
                    ),
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
      <Box className="flex justify-between pt-2 pb-1">
        <Button
          variant="text"
          onClick={handleHideAllColumns}
          className="normal-case text-[14px] font-medium text-[#2E3F4C]"
        >
          Hide All
        </Button>
        <Button
          variant="text"
          onClick={handleShowAllColumns}
          className="normal-case text-[14px] font-medium text-[#2E3F4C]"
        >
          Show All
        </Button>
      </Box>
    </Box>
  );
};

export default ColumnsVisibilityPanel;
