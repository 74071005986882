import API from '@aws-amplify/api-rest';

import { ICreateSearchList, IMidtierTable, IReqMidtier, ISearchList, IUpdateSearchList } from '@/types';

export const getMidtierTable = ({ currency, body }: IReqMidtier): Promise<IMidtierTable[]> =>
  API.post('CDPAPI', `/midtier?currency=${currency}`, { body }).then((response) => response.data);

export const getMidtierTableCount = ({ currency, body }: IReqMidtier): Promise<number> =>
  API.post('CDPAPI', `/midtier?currency=${currency}`, { body }).then((response) => response.data[0].row_count);

export const getSearchLists = (): Promise<ISearchList[]> =>
  API.get('CDPAPI', '/search_lists', {}).then((response) => response.search_lists);

export const getSearchListById = (id: string): Promise<ISearchList> => API.get('CDPAPI', `/search_list/${id}`, {});

export const createSearchList = (body: ICreateSearchList): Promise<ISearchList> =>
  API.post('CDPAPI', '/search_list', { body });

export const updateSearchList = ({ id, body }: { id: string; body: IUpdateSearchList }): Promise<ISearchList> =>
  API.patch('CDPAPI', `/search_list/${id}`, { body });

export const removeSearchList = (id: string): Promise<ISearchList> => API.del('CDPAPI', `/search_list/${id}`, {});

export const postSmartSearch = async ({ text }: { text: string }): Promise<{ suggested_query: string }> =>
  await API.post('CDPAPI', '/smart_search', { body: { user_input: text } });
