/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { TextField, Box, Button, InputLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from '@aws-amplify/auth';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { readLocalStorageValue } from '@/Utils/local-storage-utils';

import awsService from '../awsService';
import { actions as userActions } from '../slices/user';

import classes from './login.module.css';
import '../App.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isUserNameValid: false,
      userNameError: 'Enter Valid username',
      passwordError: 'Enter Valid password',
      isLoginFailed: false,
      loaded: false,
      loggingIn: false,
    };
  }

  // Handle flashing screen
  componentDidMount = async () => {
    const { history, caseCodeFormData } = this.props;
    const res = await awsService.userLoggedIn();
    const { username: email } = await Auth.currentAuthenticatedUser();
    const isCaseCodeProvided = !isEmpty(caseCodeFormData);
    const redirectUrl = readLocalStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL);

    if (res) {
      console.log('User is logged in, redirecting');

      awsService.setAuthenticationStatus(true);
      this.setState({ loaded: true });
      history.push(isCaseCodeProvided ? redirectUrl : '/homePage');

      this.props.setEmail(email.split('_')[1]);
      this.props.setIsAuth(true);
    }
  };

  handleLogin = async () => {
    let { username, password } = this.state;

    this.setState({ loggingIn: true });
    let res = await awsService.authenticate(username, password);
    const { history, caseCodeFormData } = this.props;
    const isCaseCodeProvided = !isEmpty(caseCodeFormData);
    const redirectUrl = readLocalStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL);

    if (!res) {
      console.log('Waiting for token...');
    } else if (res.status === 'error') {
      this.setState({ isLoginFailed: true, loggingIn: false });
    } else {
      history.push(isCaseCodeProvided ? redirectUrl : '/homePage');
      this.setState({ loggingIn: false });
    }
  };

  handleKeypress = (event) => {
    let code = event.keyCode || event.which;

    //it triggers by pressing the enter key
    if (code === 13) {
      this.handleLogin();
    }
  };

  handleChange = (event) => {
    const { name } = event.target;
    const newValue = event.target.value;

    this.setState({ [name]: newValue });
  };

  render() {
    return (
      <Box
        sx={{ display: 'flex' }}
        p={20}
        data-testid="loginComponent"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '100',
            width: 315,
            borderRadius: 2,
          }}
          px={3}
          py={5}
          mx="auto"
        >
          <Typography
            variant="h1"
            mb={2}
            alignItems="center"
            fontWeight="bold"
            color="#666666"
            text-align="center"
            className={classes.loginTitle}
          >
            Welcome!
          </Typography>

          <InputLabel
            htmlFor="username"
            sx={{ fontSize: '13px', padding: '3px' }}
          >
            username
          </InputLabel>
          <TextField
            id="outlined-basic"
            variant="outlined"
            name="username"
            data-testid="username-field"
            my="4px"
            value={this.state.username}
            onChange={this.handleChange}
            onKeyPress={this.handleKeypress}
            sx={{ backgroundColor: 'rgb(245, 245, 245) !important', maxWidth: '310px' }}
            InputProps={{
              className: 'text-input-material',
            }}
            // error={this.state.isUserNameValid}
            // helperText={this.state.isUserNameValid ? this.state.userNameError : ' '}
          />
          <Button
            id="login-button"
            disabled={this.state.loggingIn}
            className={classnames('px-3 mt-4', {
              'bg-[#ddd]': this.state.loggingIn,
              'bg-[#cc0100] text-white': !this.state.loggingIn,
            })}
            onClick={this.handleLogin}
            data-testid="login-using-sso"
          >
            <span className="flex items-center relative">
              Login Using SSO
              {this.state.loggingIn ? (
                <CircularProgress
                  color="info"
                  size={18}
                  sx={{ ml: 1, position: 'absolute', left: '100%' }}
                />
              ) : null}
            </span>
          </Button>
          {this.state.isLoginFailed && (
            <Typography
              variant="h3"
              color="error"
              my={2}
              fontWeight="bold"
            >
              Login Failed
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
}

export default connect(
  (state) => ({
    caseCodeFormData: state.caseCode.formData,
  }),
  {
    setEmail: userActions.setEmail,
    setIsAuth: userActions.setIsAuth,
  },
)(Login);
