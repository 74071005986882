import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';

import { useMutationUpdateSearchList } from '@/hooks/queries/search-list/use-mutation-update-search-list';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQuerySearchLists } from '@/hooks/queries/search-list/use-query-search-lists';
import { actions } from '@/slices/custom-screen';
import { useModal } from '@/hooks/use-modal';
import { useMenu } from '@/hooks/use-menu';
import { MODALS } from '@/constants';

export const SearchListControls = () => {
  const dispatch = useDispatch();
  const query = useQuerySearchLists();
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const { handleOpen: handleOpenNewListDialog } = useModal(MODALS.CREATE_NEW_LIST);
  const mutation = useMutationUpdateSearchList();
  const selectedCompanies = useShallowSelector((state) => state.customScreen.selectedCompanies);
  const areCompaniesSelected = selectedCompanies.length > 0;
  const searchLists = query.data ?? [];

  const handleResetCompanies = () => {
    dispatch(actions.reset());
  };

  const handleOpenDialog = (event) => {
    searchLists.length > 0 ? handleClick(event) : handleOpenNewListDialog();
  };

  const handleOpenDialogFromMenu = () => {
    handleOpenNewListDialog();
    handleClose();
  };

  const updateSearchList = (list_id) => () => {
    mutation.mutate(list_id);
  };

  return (
    areCompaniesSelected && (
      <>
        <span className="flex items-center bg-bluegray-900 rounded rounded-r-none font-bold m-auto text-white pl-3 pr-2 text-xs h-full">
          {selectedCompanies.length} companies selected
          <IconButton
            onClick={handleResetCompanies}
            className="text-white p-1 ml-0"
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </span>

        <Button
          className="bg-bluegray-900 rounded-none text-white px-3 text-xs add-list-button"
          onClick={handleOpenDialog}
        >
          Add to list
        </Button>

        <Menu
          className="p-0"
          classes={{
            list: 'p-0',
          }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
        >
          <MenuItem
            className="flex gap-2 font-bold text-sm py-0 px-4"
            onClick={handleOpenDialogFromMenu}
          >
            <AddIcon fontSize="small" />
            <span>Create new list</span>
          </MenuItem>

          {searchLists.map(({ list_id, title, pinned_company_ids }) => (
            <MenuItem
              key={list_id}
              className="py-0 px-4"
              onClick={updateSearchList(list_id)}
            >
              {title} - {pinned_company_ids.length}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};
