import React from 'react';

export const Chevron = ({ className }) => (
  <svg
    className={className}
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50001 10C1.24401 10 0.988006 9.902 0.793006 9.707C0.402006 9.316 0.402006 8.684 0.793006 8.293L4.09801 4.988L0.918006 1.695C0.535006 1.297 0.546006 0.664005 0.943006 0.281005C1.34101 -0.101995 1.97401 -0.0909952 2.35701 0.305005L6.21901 4.305C6.59801 4.698 6.59301 5.321 6.20701 5.70701L2.20701 9.707C2.01201 9.902 1.75601 10 1.50001 10Z"
      fill="#484848"
    />
  </svg>
);
