import { createSlice } from '@reduxjs/toolkit';
import { Position } from '@/types/ast-tree-models';

type IModalData = { isOpen: boolean; data: Record<string, unknown> | Position };

const initialState = {} as Record<string, IModalData>;

export const { reducer, actions } = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload: { id, data } }) => {
      state[id] = { isOpen: true, data };
    },
    closeModal: (state, { payload: { id, data } }) => {
      state[id] = { isOpen: false, data };
    },
  },
});

export type TState = typeof initialState;
