import { useQuery } from '@tanstack/react-query';
import API from '@aws-amplify/api-rest';

import { useShallowSelector } from '../use-shallow-selector';
import { FiltersAvailable } from '@/types';

export const useQueryAvailableFilters = () => {
  const currency = useShallowSelector((state) => state.customScreen.currency);

  return useQuery(
    ['/filters/available', currency],
    (): Promise<FiltersAvailable> =>
      API.get('CDPAPI', `/filters/available?currency=${currency}`, {}).then((response) => response.data),
    {
      enabled: !!currency,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );
};
