import React from 'react';
import { useParams } from 'react-router';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useMenu } from '@/hooks/use-menu';
import { SearchListMenu } from '@/Components/Shared/SearchListMenu';
// import { IconShare } from '@/Components/Icons/IconShare';
// import { IconClear } from '@/Components/Icons/IconClear';

export const SearchControls = ({
  isLoading,
  data,
}) => {
  const { id } = useParams();
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  return (
    <div className="flex items-center gap-5">
      {isLoading ? (
        <Skeleton
          width={200}
          height={30}
          className="bg-[#0000000f]"
        />
      ) : (
        <Button
          className="text-2xl text-[#666666] py-0 px-1"
          endIcon={<ArrowDropDownIcon fontSize="small" />}
          onClick={handleClick}
        >
          {data?.title}
        </Button>
      )}

      <SearchListMenu
        listId={id}
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        removeWithRedirect
      />

      {/* <Button
        variant="outlined"
        className="text-[#2E3F4C] border-0 px-2"
        startIcon={<IconShare fontSize="small" />}
      >
        Share
      </Button>

      <Button
        variant="outlined"
        className="text-[#2E3F4C] border-0 px-2"
        startIcon={<IconClear fontSize="small" />}
      >
        Clear
      </Button> */}
    </div>
  );
};
