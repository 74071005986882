import { rest } from 'msw';

export const columnMapperDataMock = [
  {
    'Backend Name': 'self_financials_revenue___',
    'Display Name': 'Revenue',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: 'USD',
    NUMERIC_PRECISION: 2.0,
    'Display Header': 'Financials',
    'Display Rank': 400.0,
  },
  {
    'Backend Name': 'self_firmo_employeeCount___',
    'Display Name': 'Employee Count',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: '#',
    NUMERIC_PRECISION: 0.0,
    'Display Header': 'Firmographics',
    'Display Rank': 300.0,
  },
  {
    'Backend Name': 'self_firm_statusL2___',
    'Display Name': 'Status Detail',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Firmographics',
    'Display Rank': 315.0,
  },
  {
    'Backend Name': 'parent_sector_sector___',
    'Display Name': 'Parent Sector',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 620.0,
  },
  {
    'Backend Name': 'parent_sector_industryGroup___',
    'Display Name': 'Parent Industry Group',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 630.0,
  },
  {
    'Backend Name': 'parent_sector_industry___',
    'Display Name': 'Parent Industry',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 625.0,
  },
  {
    'Backend Name': 'parent_sector_subIndustry___',
    'Display Name': 'Parent Sub-Industry',
    FILTER_COLUMN: 'Y',
    NUMERIC_TYPE: null,
    NUMERIC_PRECISION: null,
    'Display Header': 'Parent',
    'Display Rank': 635.0,
  },
];

export const columnMapperController = [
  rest.get(`${process.env.REACT_APP_API_URL}/column_mapper`, (_, res, ctx) =>
    res(
      ctx.json({
        data: columnMapperDataMock,
      }),
    ),
  ),
];
