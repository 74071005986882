import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

import mainLogo from '../assets/helix_find_logo.svg';
import { IconEnvelope } from './Icons/IconEnvelope';
import { IconHelp } from './Icons/IconHelp';
import { SelectCurrency } from './SelectCurrency';
import { IconHome } from '@/Components/Icons/IconHome';
import '../App.css';

const SupportTypography = styled(Typography)(() => ({
  '&.MuiTypography-body1': {
    fontSize: '15px',
  },
}));

const EMAIL = 'helixfind@bain.com';
const HELP_LINK = 'https://iris-sites.bain.com/private-equity/priorities/helix/helix-find/?contentTabs=home';

const HEADER_LINKS = [
  { id: 1, href: HELP_LINK, text: 'Help', icon: <IconHelp /> },
  { id: 2, href: `mailto:${EMAIL}?subject=Support`, text: 'Contact us', icon: <IconEnvelope /> },
];

const shouldCurrencyShown = (path) => path === '/customScreen' || path.includes('/search-list');

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routerObject: [
        { title: 'Home', routePath: 'homePage' },
        { title: 'Quick Screen', routePath: 'quickScreen' },
        { title: 'Custom Screen', routePath: 'customScreen' },
        { title: 'Saved Screeners', routePath: 'search-lists' },
        { title: 'Data', routePath: '' },
        { title: 'Swagger (API Documentation)', routePath: 'swagger' },
      ],
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
  };

  handleLogoClick = () => {
    const { history } = this.props;

    history.push('/homePage');
  };

  render() {
    return (
      <>
        <div
          className="border-b border-[#dddddd]"
          data-testid="headerComponent"
        >
          <Box className="px-5 items-center flex">
            <Box className="flex w-full">
              <div className="w-full flex justify-between">
                <div className="flex">
                  <div
                    className="flex items-center cursor-pointer border-r"
                    onClick={this.handleLogoClick}
                    data-testid="helix-find-logo"
                  >
                    <img
                      src={mainLogo}
                      className="mt-2"
                      style={{ width: 132, height: 46 }}
                      alt="logo"
                    />
                  </div>

                  <div className="m-auto">
                    <Link to="/search-lists">
                      <IconButton size="large">
                        <IconHome />
                      </IconButton>
                    </Link>
                  </div>
                </div>

                <div className="gap-6	supportOptions items-center">
                  {shouldCurrencyShown(location.pathname) && <SelectCurrency selectClassNames="py-1 pl-2 pr-8" />}

                  {HEADER_LINKS.map(({ id, href, text, icon }) => (
                    <SupportTypography
                      key={id}
                      variant="body1"
                    >
                      <a
                        href={href}
                        className="helpLink text-[#2E3F4C] flex gap-x-2 items-center font-medium text-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {icon}
                        {text}
                      </a>
                    </SupportTypography>
                  ))}
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
