import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import _ from 'lodash';

import { Nullish } from '@/types';

type TData = {
  text: Nullish<string>;
  description: Nullish<string>;
  Icon: Nullish<typeof DoneAllOutlinedIcon>;
  props?: Nullish<{ style: { fill: string } }>;
} | null;

export const getConfidenceLevelData = _.cond<number, TData>([
  [_.isNil, _.constant(null)],
  [
    (score) => score < 0.3,
    _.constant({
      text: 'LOW',
      description: 'Value from a single lower confidence source',
      Icon: WarningAmberOutlinedIcon,
      props: { style: { fill: 'red' } },
    }),
  ],
  [
    (score) => score >= 0.3 && score <= 0.7,
    _.constant({
      text: 'NORMAL',
      description: 'Value from a single non-high confidence source',
      Icon: null,
    }),
  ],
  [
    (score) => score > 0.7 && score <= 0.9,
    _.constant({
      text: 'HIGH',
      description: 'Multiple high/normal confidence sources report a closely similar value',
      Icon: CheckCircleOutlinedIcon,
      props: { style: { fill: 'green' } },
    }),
  ],
  [
    (score) => score > 0.9,
    _.constant({
      text: 'VERY HIGH',
      description: 'Self-reported actuals from one or more high confidence sources',
      Icon: DoneAllOutlinedIcon,
      props: { style: { fill: 'green' } },
    }),
  ],
]);
