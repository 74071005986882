import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { useQueryTable } from './queries/use-query-table';
import { actions as filterActions } from '@/slices/filters';
import { actions as searchActions } from '@/slices/search';
import { actions as tableActions } from '@/slices/table';
import { actions as screenActions } from '@/slices/custom-screen';

const QUERY_KEYS_TO_CLEAR = ['filter-tree', 'table-midtier', 'table-midtier-count'];

export const useScreener = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const query = useQueryTable();

  const resetAll = useCallback(() => {
    dispatch(filterActions.resetFilters());
    dispatch(searchActions.resetSearch());
    dispatch(tableActions.resetTable());
    dispatch(screenActions.reset());

    QUERY_KEYS_TO_CLEAR.forEach((key) => {
      queryClient.removeQueries({ queryKey: [key] })
    });

    query.refetch();
  }, [dispatch, query, queryClient]);

  return {
    resetAll,
  };
};
