import { useQuery } from '@tanstack/react-query';
import API from '@aws-amplify/api-rest';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import { useShallowSelector } from '../use-shallow-selector';
import { addPendoScript } from '@/services/pendo';

type TSecrets = {
  mui: string;
  pendo: string;
};

export const useQuerySecrets = () => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  return useQuery(
    ['secrets'],
    (): Promise<TSecrets> => API.get('CDPAPI', '/user_interface_secrets', {}),
    {
      enabled: isAuth,
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess: async (response) => {
        LicenseInfo.setLicenseKey(response?.mui);

        await addPendoScript(response?.pendo);
      },
      onError: (error) => {
        alert(error);
      },
    },
  );
};
