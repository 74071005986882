import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Search from '@mui/icons-material/Search';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Storage from '@mui/icons-material/Storage';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { MODALS, LOCAL_STORAGE_KEYS } from '@/constants';
import { isEmpty } from 'lodash';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { useLocalStorage } from 'usehooks-ts';

import awsService from '../awsService';
import classes from './homePage.module.css';
import '../App.css';
import { useHistory } from 'react-router';

const NAVIGATION_SCREENERS = [
  {
    title: 'Sustainability Pipeline',
    contentText: 'Build custom queries from scratch, access company profiles and create overviews',
    buttonLabel: 'Explore',
    icon: 'search',
    disabled: false,
    routePath: 'customScreen',
  },
];

const HomePage = () => {
  const history = useHistory();
  const [userName, setUserName] = useState('');
  const isCaseCodeProvided = !isEmpty(useShallowSelector((state) => state.caseCode.formData));
  const [redirectUrl, setRedirectUrl] = useLocalStorage(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');
  const { handleOpen, isOpen } = useModal(MODALS.CASE_CODE);

  useEffect(() => {
    if (isOpen) {
      setRedirectUrl('');
    }
  }, [isOpen, setRedirectUrl]);

  if (!isOpen && !isCaseCodeProvided) {
    handleOpen({ redirectUrl });
  }

  useEffect(() => {
    awsService.getUser().then((data) => {
      if (data?.username) {
        const userNameStripped = data.username.substring(data.username.indexOf('_') + 1);

        setUserName(userNameStripped);
      }
    });
  }, []);

  const routeToSelectedPage = (selectedPage) => {
    if (selectedPage) history.push(`/${selectedPage}`);
  };

  return (
    <Box
      p={1}
      data-testid="homePageComponent"
    >
      <Typography
        variant="h2"
        ml={1}
        mt={10}
        className={classes.welcome}
        id="username"
      >
        Welcome {userName}!
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {NAVIGATION_SCREENERS.map(({ title, contentText, icon, disabled, routePath, buttonLabel, label }, idx) => (
          <Box
            key={idx}
            bg="backgroundSurface"
            p={4}
            color="#666666"
            sx={{ backgroundColor: 'rgb(245, 245, 245)' }}
          >
            <Card
              variant="outlined"
              sx={{
                position: 'relative',
                maxWidth: 380,
                padding: '7px',
                backgroundColor: 'white',
                color: 'rgb(102, 102, 102)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {label ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '20px',
                      right: '20px',
                      backgroundColor: '#0484E7',
                      color: '#FFFFFF',
                      borderRadius: '4px',
                      padding: '3px 4px',
                      fontWeight: 500,
                      fontSize: '12px',
                      lineHeight: '16px',
                    }}
                  >
                    {label}
                  </Box>
                ) : null}
                <div className="hmCardIcon">
                  {icon === 'Funnel' && (
                    <FilterAltOutlined
                      className="material-icons"
                      sx={{ fontSize: 70 }}
                    />
                  )}
                  {icon === 'search' && (
                    <Search
                      className="material-icons"
                      sx={{ fontSize: 70 }}
                    />
                  )}
                  {icon === 'Harddrive' && (
                    <Storage
                      className="material-icons"
                      sx={{ fontSize: 70 }}
                    />
                  )}
                </div>
                <CardContent sx={{ padding: '0px' }}>
                  <Typography
                    variant="h3"
                    mt={1}
                    mb={2}
                    className={classes.screenTitle}
                    id={`${title}`}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    mb={3}
                    className={classes.screenText}
                  >
                    {contentText}
                  </Typography>
                </CardContent>
                <CardActions sx={{ width: '95%', justifyContent: 'center' }}>
                  <Button
                    id={`explore-button-${idx}`}
                    onClick={() => routeToSelectedPage(routePath)}
                    className={classnames('w-full p-2', {
                      'bg-[#cc0000] text-white': !disabled,
                      'bg-[#0000001f] text-[#00000042]': disabled,
                    })}
                    disabled={disabled}
                    data-testid={`homepage-explore-${buttonLabel.toLowerCase().replaceAll(' ', '-')}`}
                  >
                    {buttonLabel}
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HomePage;
