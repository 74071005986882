import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchProfile } from './thunks/fetch-profile';
import { IProfile, INews, IMultyYear, IMergesAndAcquisitions } from '@/types';

const initialState = {
  isLoading: false,
  profile: [] as IProfile[],
  news: [] as INews[],
  multiyear: {} as IMultyYear,
  subsidiaries: [] as string[],
  mergesAndAcquisitions: [] as IMergesAndAcquisitions[],
};

export const slice = createSlice({
  name: 'companyProfile',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setCompanyData: (state, { payload }: PayloadAction<Omit<TState, 'isLoading'>>) => {
      state.profile = payload.profile;
      state.news = payload.news;
      state.multiyear = payload.multiyear;
      state.subsidiaries = payload.subsidiaries;
      state.mergesAndAcquisitions = payload.mergesAndAcquisitions;
    },
    clearProfile: (state) => {
      state.profile = [];
      state.news = [];
      state.multiyear = {} as IMultyYear;
      state.subsidiaries = [];
      state.mergesAndAcquisitions = [];
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
  fetchProfile,
};

export type TState = typeof initialState;