import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useShallowSelector } from './use-shallow-selector';
import { actions } from '@/slices/modal';

export const useModal = (modalId: string) => {
  const dispatch = useDispatch();
  const isOpen = useShallowSelector(state => state.modal?.[modalId]?.isOpen ?? false);
  const data = useShallowSelector(state => state.modal?.[modalId]?.data);

  const handleOpen = useCallback((additionalData = {}) => {
    dispatch(actions.openModal({ id: modalId, data: additionalData }));
  }, [dispatch, modalId]);

  const handleClose = useCallback(() => {
    dispatch(actions.closeModal({ id: modalId, data: {} }));
  }, [dispatch, modalId]);

  return {
    isOpen,
    data,
    handleOpen,
    handleClose,
  };
};
