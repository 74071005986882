import { useQuery } from '@tanstack/react-query';
import API from '@aws-amplify/api-rest';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { ICollumnMapper } from '@/types';

export const useQueryColumnMapper = () => {
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const isAuth = useShallowSelector((state) => state.user.isAuth) ;

  return useQuery(
    ['column-mapper', currency],
    (): Promise<ICollumnMapper[]> => API.get('CDPAPI', `/column_mapper?currency=${currency}`, {}).then(res => res.data),
    {
      enabled: isAuth,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );
};
