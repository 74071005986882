import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';

import { useMutationRemoveSearchList } from '@/hooks/queries/search-list/use-mutation-remove-search-list';

export const SearchListMenu = ({
  listId,
  isOpen,
  anchorEl,
  handleClose,
  removeWithRedirect = false,
}) => {
  const mutation = useMutationRemoveSearchList(removeWithRedirect);

  const handleRemove = () => {
    mutation.mutate(listId);
  };

  return (
    <Menu
      className="p-0"
      classes={{
        list: 'p-0',
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
    >
      <MenuItem disabled>View details</MenuItem>
      <MenuItem
        disabled={mutation.isLoading}
        onClick={handleRemove}
        className="flex justify-between"
      >
        <span>Delete</span>

        <CircularProgress
          className={classnames({
            hidden: !mutation.isLoading,
          })}
          size={20}
        />
      </MenuItem>
    </Menu>
  );
};
