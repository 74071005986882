import React from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { removeSearchList } from '@/services/api';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

export const useMutationRemoveSearchList = (removeWithRedirect: boolean) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationKey: ['remove-search-list'],
    mutationFn: (id: string) => removeSearchList(id),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['search-lists'] });

      if (removeWithRedirect) {
        history.push('/search-lists');
      }

      enqueueSnackbar(
        <NotificationMessage
          title={`${response.pinned_company_ids.length} companies removed from ${response.title}`}
        />,
        { variant: 'success' },
      );
    },
  });
};
