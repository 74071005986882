import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { Box, Dialog, DialogActions, DialogTitle, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import styled from 'styled-components';
import { take } from 'rxjs';
import API from '@aws-amplify/api-rest';
import { Close } from '@mui/icons-material';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';

import { ExportButton } from '../../Shared/Buttons/ExportButton';
import { NotificationMessage } from '../../Shared/Notifications/NotificationMessage';
import { getFilters, normalizeFilters } from '@/Utils/filters';
import { useSearchHistory } from '@/hooks/use-save-search-query';
import { FIXED_ELASTIC_SEARCH_OUTPUT, MODALS } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { searchSubject } from '@/Utils/subjects';
import { isCaseCodeValid } from '@/Utils/case-code-utils';
import { useModal } from '@/hooks/use-modal';

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '9px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '&.MuiButtonBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  '&.Mui-checked': {
    color: '#c00',
  },
}));

const ExportDialog = () => {
  const { isOpen, handleClose, data } = useModal(MODALS.EXPORT);
  const { isOpen: isCompanyProfile } = useModal(MODALS.COMPANY_PROFILE);
  const [exportOption, setExportOption] = useState('all');
  const location = useLocation();
  const { saveQuery } = useSearchHistory();
  const selectedCompanies = useShallowSelector((state) => state.customScreen.selectedCompanies);
  const totalRows = useShallowSelector((state) => state.table.totalRows);
  const total = useShallowSelector((state) => state.search.bainIds.total);
  const storedFilters = useShallowSelector((state) => state.filters);
  const filters = useShallowSelector((state) => getFilters(state.filters));
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);
  const searchQueryTree = useShallowSelector((state) => state.search.searchQueryTree);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const email = useShallowSelector((state) => state.user.email);
  const caseCodeData = useShallowSelector((state) => state.caseCode.formData);
  const bainIds = useShallowSelector((state) => state.search.bainIds.data);
  const noCaseCode = !caseCodeData.caseCode;
  const areIdsEmpty = _.isEmpty(selectedCompanies);
  const countToUse = total || totalRows;
  const snackbar = useSnackbar();

  const handleExportData = () => {
    const isCustomScreen = location.pathname === '/customScreen';

    let body = {
      email,
      sql_query: true,
      ...(!_.isEmpty(bainIds) && { self_ids_id__cdp_: bainIds }),
      ...(!_.isEmpty(includedNullList) && {
        include_null_list: includedNullList,
      }),
      ...(isCustomScreen && { free_search_text: searchQuery }),
      ...normalizeFilters(filters),
    };

    if (isCompanyProfile) {
      body.company_profile = true;
      body.bainIds = bainIds;
    } else if (exportOption === 'selected') {
      body.company_profile = true;
      body.bainIds = selectedCompanies.map(({ id }) => String(id));
    }

    // add case code
    if (noCaseCode) {
      body['project_name'] = caseCodeData.projectName;
      body['case_type'] = caseCodeData.caseType;
      body['project_type'] = caseCodeData.projectType;
    } else {
      body['case_code'] = caseCodeData.caseCode;
    }

    try {
      API.post('CDPAPI', `/midtier/export?currency=${currency}`, {
        body: body,
      });
      snackbar.enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
            Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );
    } catch (err) {
      snackbar.enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      });
    }

    handleClose();

    if (saveQuery) {
      // TODO: refactor, this subscription is needed to get isUniq value
      searchSubject.pipe(take(1)).subscribe(({ isUniq }) => {
        saveQuery({
          searchText: searchQuery,
          companiesCount: exportOption === 'selected' ? selectedCompanies.length : bainIds.filter((x) => +x > 0).length, // possible value: ['-1']
          isLucene: !!searchQueryTree,
          isExport: true,
          filters: storedFilters,
          isUniq,
        });
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      id="dialog-box"
    >
      <div style={{ backgroundColor: 'white', position: 'absolute', right: '5px', padding: '10px' }}>
        <Close
          id="close-icon"
          onClick={handleClose}
          sx={{ cursor: 'pointer', color: 'black' }}
        />
      </div>
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          <b>Export Results</b>
        </Typography>
        {!isCompanyProfile && countToUse > FIXED_ELASTIC_SEARCH_OUTPUT && (
          <Alert
            severity="warning"
            className="mb-4 mt-3 px-3 py-0.5 whitespace-pre-line bg-[#FEF7EA]"
          >
            {`You can only export the first ${FIXED_ELASTIC_SEARCH_OUTPUT.toLocaleString('en')} companies.
Refine your search or if you need to exceed this limit contact the `}

            <a
              className="text-cblue-900"
              href="mailto:helixfind@bain.com"
            >
              Helix Find Team
            </a>
          </Alert>
        )}
        {isCompanyProfile ? (
          <Typography
            variant="body1"
            gutterBottom
          >
            Please select "Export" to export all data for the selected company.
          </Typography>
        ) : (
          <Typography
            variant="body1"
            gutterBottom
          >
            Please select an export option:
          </Typography>
        )}
      </DialogTitle>
      <DialogActions
        sx={{ width: '500px', backgroundColor: 'white', color: 'black', padding: '15px' }}
        id="dialog-actions"
      >
        {!isCompanyProfile ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <RadioGroup
                aria-label="export"
                name="export"
                value={exportOption}
                onChange={(_, value) => setExportOption(value)}
                style={{
                  position: 'relative',
                }}
                id="radio-group"
              >
                <FormControlLabel
                  value="all"
                  control={<StyledRadio id="export-all-radio" />}
                  label="Export summary data for all search results (excludes time-series data on financials, news, M&A, others...)"
                  id="export-all"
                />
                <FormControlLabel
                  value="selected"
                  control={<StyledRadio id="export-selected-radio" />}
                  disabled={areIdsEmpty}
                  label="Export all data for only selected companies"
                  id="export-selected"
                />
              </RadioGroup>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="body1"
                gutterBottom
              >
                {isCaseCodeValid(caseCodeData, noCaseCode) ? '' : '* Starred fields are mandatory'}
              </Typography>

              <ExportButton
                id="export-button-1"
                onClick={handleExportData}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ExportButton
              id="export-button-2"
              onClick={handleExportData}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
